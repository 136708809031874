import React, { useEffect, useState } from "react";
import MainSidebar from "./Sidebar";
import SubContent from "./MainContent";
import SubHeader from "./MainHeader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AboutSection from "../aboutsection/AboutSection";
import {
  API_ENDPOINTS,
  SITE_URL,
  toAbsoluteUrl,
} from "../../assets/helpers/AssetHelpers";
import ParentCard from "../parentCard/parentCard";
import "./maincontent.css";
import { Categories } from "../../types/types";
interface CategoriesApiResponse {
  data: {
    all_category: Omit<Categories, 'subcategories'>[];
  };
}

interface CmsContent {
  id: string;
  content: string;
}

interface CmsContentApiResponse {
  data2: any[];
}

const SubCategory = () => {
  const { category_name } = useParams();

  const navigate = useNavigate();
  const [data1, setData1] = useState<CategoriesApiResponse>({
    data: {
      all_category: [],
    },
  });

  const [data2, setData2] = useState<CmsContent[]>([]);

  useEffect(() => {
    fetch(API_ENDPOINTS.getCMSCategory, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
      body: `site=${SITE_URL}`,
    }).then((result) => {
      result.json().then((resp) => {
        setData1(resp);
      });
    });

    fetch(API_ENDPOINTS.getCMSContent, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
      body: `site=${SITE_URL}`,
    })
      .then((result) => result.json())
      .then((data) => {
        if (data && data.data) {
          setData2(data.data);
        } else {
          console.error("Invalid API response format:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching CMS content:", error);
      });

  }, []);

  const filteredData = data1?.data?.all_category?.map((item: any) => {
    const subcategories = data2?.filter(
      (content: any) => content.cms_category === item.category_name
    );
    const newItem = {
      ...item,
      subcategories,
    };

    return newItem;
  });

  const mappedData = filteredData?.filter(
    (filterData: any) => filterData?.parent_cms_category === category_name
  );

  const getSubcategories = (parentCategory: any, allCategories: any) => {
    const subcategories = allCategories.filter(
      (category: any) => category.parent_cms_category === parentCategory.id
    );

    if (subcategories.length === 0) {
      return null;
    }

    return subcategories.map((subcategory: any) => ({
      ...subcategory,
      subcategories: getSubcategories(subcategory, allCategories),
    }));
  };
  const nestedCategories = data1?.data?.all_category
    .filter(
      (category) =>
        category.parent_cms_category === null ||
        category.parent_cms_category === ""
    )
    .map((category) => ({
      ...category,
      subcategories: getSubcategories(category, data1?.data?.all_category),
    }));
  const subcategoriesArray = nestedCategories.flatMap(
    (category) => category.subcategories
  );

  function toggleNavbar(event: { currentTarget: any }) {
    const button = event.currentTarget;
    const isExpanded = button.getAttribute("aria-expanded") === "false";

    if (isExpanded) {
      button.setAttribute("data-bs-state", "closed");
    } else {
      button.setAttribute("data-bs-state", "open");
    }
  }
  const className =
    process.env.REACT_APP_FULLWIDTH === "1"
      ? "col mx-lg-5 mb-0 "
      : "col-lg-10 mx-auto mb-0 px-4 px-lg-3";

  if (!category_name) {
    navigate('/no-results-found');
    return null;
  }

  return (
    <>
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col m-lg-5 mb-0 mb-lg-0">
              <div className="row">
                <div className={className}>
                  <SubHeader />
                  <ParentCard />
                  <div className="row">
                    <div className="col-lg-12 col-12 mt-lg-4 my-4 p-0 mb-0 d-none d-lg-block">
                      <div className="accordion my-4" id="sideBarAccordian">
                        {nestedCategories.map((category: any, index: any) => {
                          return (
                            <>
                              <MainSidebar
                                category_name={category.id}
                                category={category}
                                index={index}
                                url_category_name={category_name}
                              />
                            </>
                          );
                        })}
                      </div>
                    </div>
                    <nav className="navbar navbar-expand-lg  d-block d-lg-none">
                      <div className="container-fluid justify-content-start px-3 px-md-4 px-lg-3">
                        <button
                          className="navbar-toggler border-0 p-0 pe-3"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#navbarNavAltMarkup"
                          aria-controls="navbarNavAltMarkup"
                          aria-expanded="false"
                          aria-label="Toggle navigation"
                          onClick={toggleNavbar}
                        >
                          <span className="navbar-toggler-icon"></span>
                        </button>
                        <a
                          className="navbar-brand in_reg fs-18 lh-24"
                          href="#"
                        >
                          View all categories
                        </a>
                        <div
                          className="collapse navbar-collapse"
                          id="navbarNavAltMarkup"
                        >
                          <div className="accordion my-4" id="sideBarAccordian">
                            {nestedCategories.map(
                              (category: any, index: any) => {
                                return (
                                  <>
                                    <MainSidebar
                                      category_name={category.id}
                                      category={category}
                                      index={index}
                                      url_category_name={category_name!}
                                    />
                                  </>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </nav>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SubCategory;
