import React from "react";
import "./style.css";

interface ListItemProps {
  href: string;
  text: string;
}

export const ListItem: React.FC<ListItemProps> = ({ href, text }) => {
  const isBold = text === "Updates" || text === "Circulars";

  return (
    <li className="my-lg-3 my-4">
      {text === "Circulars" ? (
        <a href={href} className="color28 in_bold lh-19 nav-sub-bold fs-18 li-link">
          {text}
        </a>
      ) : isBold ? (
        <span className="color28 in_bold lh-19 nav-sub-bold fs-18">{text}</span>
      ) : (
        <a href={href} className="color28 in_med lh-19 li-link">
          {text}
        </a>
      )}
    </li>
  );
};
