import React from "react";
import { Link, useParams } from "react-router-dom";
import { toAbsoluteUrl } from "../../assets/helpers/AssetHelpers";

const NoResultsSearch = () => {
  const { searchTerm } = useParams();

  return (
    <>
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col m-lg-5 my-md-5 mb-4 mb-lg-0">
              <div className="row">
                <div className="col-lg-10 mx-auto px-3 px-md-4 px-lg-3">
                  <div>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb cursor-pointer mt-4 mt-lg-0 global-font">
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          <a href="/" className="color1 in_reg lh-24">
                            Home
                          </a>
                        </li>
                        <li className="breadcrumb-item">
                          <a href="/" className="color1 in_reg lh-24">
                            {searchTerm}
                          </a>
                        </li>
                      </ol>
                    </nav>
                    <div className="fs-24 py-2 in_semi search-head">
                      No search result found for the keyword "{searchTerm}"
                    </div>
                    <div className="text-center mt-5">
                      <div>
                        <img
                          src={toAbsoluteUrl(
                            "herosection-images/noresult.svg"
                          )}
                          alt=""
                        />
                      </div>
                      {/* <div className="fs-18 in_med color28 lh-24 mt-4 search-list">
                        We searched high and low but couldn't find anything. Can
                        we help with another question?
                      </div> */}
                      <div className="fs-18 in_reg color28 lh-24 mt-4 search-list">
                        To find the information you need, please visit our home page at{" "}
                        <Link to={"/"} className="color1 in_med">
                          support.jainam.in{" "}
                        </Link>
                        and search for your query.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NoResultsSearch;
