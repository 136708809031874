import React from 'react';
import './footer.css';
import { useNavigate } from 'react-router-dom';
import useReRouteHook from '../../assets/hooks/useReRouteHook';
interface Link {
	text: string;
	href: string;
	isLocalPage?: string
}

interface SubSection {
	title: string;
	links: Link[];
}

interface FooterColumnProps {
	title: string;
	links: Link[];
	subSections?: SubSection[];
}

const FooterColumn: React.FC<FooterColumnProps> = ({ title, links, subSections }) => {
	const { reRouteButtonRenderer } = useReRouteHook();
	return (<div className="col-6 col-md-2 offset-lg-1 mb-3 mx-0">
		<h6 className="fs-14 in_semi lh-22">{title}</h6>
		<ul className="list-unstyled mt-3">
			{links.map((link, index) => (
				<li className="mb-1" key={index}>
					{link?.isLocalPage ?
						reRouteButtonRenderer({
							url: link.isLocalPage,
							classes: "decoration fs-12 in_reg lh-14 footer-link p-0",
							nameText: link.text
						})
						:
						<a href={link.href} className="decoration fs-12 in_reg lh-14 footer-link">
							{link.text}
						</a>}
				</li>
			))}
		</ul>
		{subSections &&
			subSections.map((subSection, index) => (
				<div key={index} className="mt-4">
					<h6 className="fs-14 in_semi lh-22">{subSection.title}</h6>
					<ul className="list-unstyled mt-3">
						{subSection.links.map((link, subIndex) => (
							<li className="mb-0" key={subIndex}>
								<a href={link.href} className="decoration fs-12 in_reg lh-14 footer-link">
									{link.text}
								</a>
							</li>
						))}
					</ul>
				</div>
			))}
	</div>
	)
};

export default FooterColumn;
