import React from 'react';
import { toAbsoluteUrl } from '../../../assets/helpers/AssetHelpers';
import './style.css';
import { ListSection } from './ListSection';
import useWindowSize from './useWindowSize';
import { Link, useNavigate } from 'react-router-dom';

const Navbar = () => {
	const { width } = useWindowSize();
	const navigate = useNavigate();
	const platformItems = [
		{ href: 'https://jainam.in/jainam-lite/', text: 'Jainam Lite' },
		{ href: 'https://jainam.in/jainam-plus/', text: 'Jainam Plus' },
		{ href: 'https://jainam.in/jainam-api/', text: 'API' },
		{ href: 'https://www.jainam.in/smartgreek/', text: 'Smart Greek' },
	];

	const investTradeItems = [
		{ href: 'https://www.jainam.in/stocks/', text: 'Stocks' },
		{ href: 'https://www.jainam.in/margin-trading-facility/', text: 'Margin Trading Facility' },
		{ href: 'https://www.jainam.in/futures-trading/', text: 'Future Trading' },
		{ href: 'https://www.jainam.in/options-trading/', text: 'Options Trading' },
		{ href: 'https://www.jainam.in/mutual-funds/', text: 'Mutual Funds' },
		// { href: '#', text: 'IPO' },
		// { href: '#', text: 'Debt Products' },
	];
	const researchItems = [
		{ href: 'https://jainam.in/jainam-reports/', text: 'Daily Updates' },
		{ href: 'https://jainam.in/jainam-reports/', text: 'Weekly Updates' },
		{ href: 'https://jainam.in/jainam-reports/', text: 'Random Updates' },
	];
	const noticeBoardItems = [
		{ href: '#', text: 'Updates' },
		{ href: 'https://www.jainam.in/general-updates/', text: 'General Updates' },
		{ href: 'https://jainam.in/nse-holidays/', text: 'Holiday Calendar' },
		{ href: 'https://www.jainam.in/circulars/', text: 'Circulars' },
	];
	function toggleNavbarState(event: { currentTarget: any }) {
		const button = event.currentTarget;
		const isExpanded = button.getAttribute('aria-expanded') === 'false';

		if (isExpanded) {
			button.setAttribute('data-bs-state', 'closed');
		} else {
			button.setAttribute('data-bs-state', 'open');
		}
	}
	const isSmallDevice = (width ?? 0) < 768;

	const offcanvasClass = isSmallDevice ? 'offcanvas-start' : 'offcanvas-top';
	const className =
		process.env.REACT_APP_FULLWIDTH === '1'
			? 'col mx-lg-5 py-2'
			: 'col-lg-10 mx-auto py-2 px-4 px-lg-3 ';

	return (
		<>
			<section id="nav-section">
				<div className="container-fluid">
					<div className="row">
						<div className="col mx-lg-5 ">
							<div className="row">
								{/* <div className="col mx-lg-5 py-2"> */}
								<div className={className}>
									<nav className="navbar navbar-expand-xl ">
										<div className="container-fluid p-0">
											<Link className="navbar-brand navbar_brand me-0" to="https://www.jainam.in/">
												<img
													className="mobile-logo-jainam"
													src={toAbsoluteUrl('navbar-section/jainam.png')}
													alt="logo"
												/>
											</Link>
											<button
												className="navbar-toggler border-0 px-0 d-none"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#navbarSupportedContent"
												aria-controls="navbarSupportedContent"
												aria-expanded="false"
												aria-label="Toggle navigation"
												onClick={toggleNavbarState}>
												<span className="navbar-toggler-icon"></span>
											</button>
											<div className="d-flex">
												<div className="me-3 d-none d-sm-block d-xl-none">
													<a
														href="https://www.jainam.in/open-demat-account/"
														target="_blank"
														className="btn-font-12 bg-color29 nav-btn btn px-4 rounded-3 w-100 py-2 text-white ">
														Open Demat Account
													</a>
												</div>
												<button
													className="btn btn_outline fs-3 d-block d-xl-none p-0"
													type="button"
													data-bs-toggle="offcanvas"
													data-bs-target="#offcanvasExample"
													aria-controls="offcanvasExample">
													<i className="fas fa-bars"></i>
												</button>
											</div>
											<div
												className="collapse navbar-collapse nav-mar-collapse"
												id="navbarSupportedContent">
												<ul className="navbar-nav me-auto mb-2 mb-lg-0">
													<li className="nav-item nav_item nav-item-mar  dropdown">
														<a
															className="nav-link nav-link-font  dropdown-toggle in_reg fs-14 px-lg-3 color28"
															href="#"
															role="button"
															// data-bs-toggle="dropdown"
															aria-expanded="false">
															Product <i className="fa-solid fa-chevron-down ms-1"></i>
														</a>
														<div
															className="dropdown-menu dropdown_menu nav-pad border-0 dropdown-menu-shadow z-index-100 show"
															data-bs-popper="static">
															<div className="row gx-0">
																<div className="col-lg-3 mt-4 mt-lg-0">
																	<ListSection title="Platform" items={platformItems} />
																</div>
																<div className="col-lg-3">
																	<ListSection title="Invest & Trade" items={investTradeItems} />
																</div>

																<div className="col-lg-6">
																	<div className="d-flex justify-content-end ">
																		<img
																			className="d-none d-lg-block"
																			src={toAbsoluteUrl(
																				'herosection-images/ProductNavbarCard.svg',
																			)}
																			alt=""
																		/>
																	</div>
																</div>
															</div>
														</div>
													</li>
													<li className="nav-item nav_item nav-item-mar  dropdown">
														<a
															className="nav-link nav-link-font  dropdown-toggle in_reg fs-14 px-lg-3 color28"
															href="#"
															role="button"
															// data-bs-toggle="dropdown"
															aria-expanded="false">
															Resources <i className="fa-solid fa-chevron-down ms-1"></i>
														</a>
														<ul
															className="dropdown-menu dropdown_menu nav-pad-x border-0 rounded-0 dropdown-menu-shadow z-index-100 show"
															data-bs-popper="static">
															<div className="row gx-0 pt-3 pt-lg-0">
																<div className="col-lg-3 nav-pad-y">
																	<ListSection title="Research" items={researchItems} />
																</div>
																<div className="col-lg-3 nav-pad-y">
																	<ListSection title="Notice Board" items={noticeBoardItems} />
																</div>
																<div className="col-lg-3 ">
																	<div className="active-bg px-3 nav-pad-y h-100 d-none d-xl-block">
																		<div>
																			<a
																				className="fs-18 in_semi mb-4 lh-22 color28 li-link"
																				role="button"
																				href="https://www.jainam.in/news/">
																				News
																			</a>
																			<div className="fs-12 in_reg my-2">
																				Get to know the rationale behind great ideas for you to make
																				sound long term investment decisions.
																			</div>
																		</div>
																		<div className="mt-3">
																			<a
																				className="fs-18 in_semi mb-4 lh-22 color28 li-link"
																				role="button"
																				href="https://www.jainam.in/blog/">
																				Blogs
																			</a>
																			<div className="fs-12 in_reg my-2">
																				Explore our blogs and guides to gain insights and learn how
																				to get started with trading and investments.
																			</div>
																		</div>
																		<div className="mt-3">
																			<a
																				className="fs-18 in_semi mb-4 lh-22 color28 li-link"
																				role="button"
																				href="https://support.jainam.in/">
																				FAQs
																			</a>
																			<div className="fs-12 in_reg my-2">
																				Find answers to your questions with our comprehensive set of
																				FAQs. If you need further assistance, our Support team is
																				here to help!
																			</div>
																		</div>
																	</div>
																</div>
																<div className="col-lg-3  nav-pad-y">
																	<div className="ps-3 d-none d-lg-block">
																		<div className="d-flex justify-content-center mb-4">
																			<img
																				src={toAbsoluteUrl('navbar-section/navbar-resource.svg')}
																				alt="logo"
																			/>
																		</div>
																		<div className="fs-18 in_bold mb-3 lh-22 color28">
																			Ho Growth
																		</div>
																		<div className="fs-14 in_reg mb-3 lh-22 color28">
																			Jainam Community limited brings you the services which can be
																			easily operated at any time and from any place.
																		</div>
																		<div className="mb-4 slide-transition">
																			<a
																				href="https://hogrowth.com/ "
																				className="color1 in_med lh-19 ">
																				Read More
																				<img
																					className="ms-2"
																					src={toAbsoluteUrl('navbar-section/next-arrow.svg')}
																					alt="logo"
																				/>
																			</a>
																		</div>
																	</div>
																</div>
															</div>
														</ul>
													</li>
													<li className="nav-item nav_item nav-item-mar ">
														<a
															className="nav-link nav_item nav-link-font  in_reg fs-14 px-lg-3 color28"
															href="https://jainam.in/pricing/">
															Pricing
														</a>
													</li>

													{/* it does not make sense to add a support button in the support website which links to jainam.in */}
													<li className="nav-item nav-item-mar ">
														<a
															className="nav-link nav-link-font  in_reg fs-14 px-lg-3 color28 "
															href="https://jainam.in/contact-us/">
															Support
														</a>
													</li>
												</ul>
												<form className="d-flex mt-3 mt-lg-0" role="search">
													<a
														href="https://www.jainam.in/open-demat-account/"
														target="_blank"
														className="bg-color29 nav-btn btn me-3 px-4 in_semi rounded-3 py-2 text-white"
														type="submit">
														Open Demat Account
													</a>
													<a
														href="https://space.jainam.in/#/"
														target="_blank"
														className="btn btn-outline-dark login-btn px-4 rounded-3 py-2 fs-16 in_semi">
														Login
													</a>
												</form>
											</div>
										</div>
									</nav>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<div
				// className={`offcanvas ${offcanvasClass} r-vh`}
				className={`offcanvas offcanvas-top r-vh`}
				tabIndex={-1}
				id="offcanvasExample"
				aria-labelledby="offcanvasExampleLabel">
				<div className="offcanvas-header px-4">
					<div className="offcanvas-title" id="offcanvasExampleLabel">
						<img src={toAbsoluteUrl('navbar-section/jainamLogo.svg')} alt="" />
					</div>
					<button
						type="button"
						className="btn-close btn_close"
						data-bs-dismiss="offcanvas"
						aria-label="Close"></button>
				</div>
				<div className="offcanvas-body px-4">
					{/* body */}
					<div className="accordion" id="accordionExample">
						<div className="accordion-item border-0">
							<h2 className="accordion-header" id="headingProduct">
								<button
									className="accordion-button px-0 accordion_button_focus accordion-button-collapsed accordion-offcanva-button fs-14 in_med bg-transparent collapsed"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#collapseProduct"
									aria-expanded="true"
									aria-controls="collapseProduct">
									Product
								</button>
							</h2>
							<div
								id="collapseProduct"
								className="accordion-collapse accordion_collapse collapse"
								aria-labelledby="headingProduct"
								data-bs-parent="#accordionExample">
								<div className="accordion-body py-1 px-0 border-bottom border-top">
									<div className="row gx-0">
										<div className="col-lg-3 mt-4 mt-lg-0">
											<ListSection title="Platform" items={platformItems} />
										</div>
										<div className="col-lg-3">
											<ListSection title="Invest & Trade" items={investTradeItems} />
										</div>
										<div className="col-lg-6">
											<div className="d-flex justify-content-end">
												<img
													className="d-none d-lg-block"
													src={toAbsoluteUrl('herosection-images/ProductNavbarCard.svg')}
													alt=""
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="accordion-item border-0">
							<h2 className="accordion-header" id="headingResources">
								<button
									className="accordion-button px-0 accordion_button_focus accordion-button-collapsed accordion-offcanva-button fs-14 in_med bg-transparent collapsed"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#collapseResources"
									aria-expanded="false"
									aria-controls="collapseResources">
									Resources
								</button>
							</h2>
							<div
								id="collapseResources"
								className="accordion-collapse accordion_collapse collapse"
								aria-labelledby="headingResources"
								data-bs-parent="#accordionExample">
								<div className="accordion-body py-1 px-0 border-bottom border-top">
									<div className="row gx-0 pt-3 pt-lg-0">
										<div className="col-lg-3 nav-pad-y">
											<ListSection title="Research" items={researchItems} />
										</div>
										<div className="col-lg-3 nav-pad-y">
											<ListSection title="Notice Board" items={noticeBoardItems} />
										</div>
										<div className="col-lg-3">
											<div className="active-bg px-3 nav-pad-y h-100 d-none d-xl-block">
												<div className="fs-18 in_semi mb-4 lh-22 color28">
													News
													<div className="fs-12 in_reg my-2">
														Lorem ipsum dolor sit amet, consectetur adipis elit. Sit enim nec, proin
														faucibus
													</div>
												</div>
												<div className="fs-18 in_semi mb-4 lh-22 color28">
													Blogs
													<div className="fs-12 in_reg my-2">
														Lorem ipsum dolor sit amet, consectetur adipis elit. Sit enim nec, proin
														faucibus
													</div>
												</div>
												<div className="fs-18 in_semi mb-4 lh-22 color28">
													FAQs
													<div className="fs-12 in_reg my-2">
														Lorem ipsum dolor sit amet, consectetur adipis elit. Sit enim nec, proin
														faucibus
													</div>
												</div>
											</div>
										</div>
										<div className="col-lg-3 nav-pad-y">
											<div className="ps-3 d-none d-xl-block">
												<div className="d-flex justify-content-center mb-4">
													<img
														src={toAbsoluteUrl('navbar-section/navbar-resource.svg')}
														alt="logo"
													/>
												</div>
												<div className="fs-18 in_bold mb-3 lh-22 color28">Ho Growth</div>
												<div className="fs-14 in_reg mb-3 lh-22 color28">
													Jainam Community limited brings you the services which can be easily
													operated at any time and from any place.
												</div>
												<div className="mb-4">
													<a href="#" className="color1 in_med lh-19">
														Read More
														<img
															className="ms-2"
															src={toAbsoluteUrl('navbar-section/next-arrow.svg')}
															alt="logo"
														/>
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="accordian-pad">
							<a
								href="https://jainam.in/pricing/"
								className="nav-link nav-link-font in_reg fs-14 px-xl-3 color28">
								Pricing
							</a>
						</div>

						<div className="accordian-pad">
							<a
								href="https://jainam.in/contact-us/"
								className="nav-link nav-link-font in_reg fs-14 px-xl-3 color28">
								Support
							</a>
						</div>
						<form className="p-3 px-0 d-block" role="search">
							<div className="row">
								<div className="col-12 col-md-6 col-lg-8">
									<a
										href="https://www.jainam.in/open-demat-account/"
										target="_blank"
										className="bg-color29 nav-btn btn px-4 rounded-3 w-100 py-2 text-white">
										Open Demat Account
									</a>
								</div>
								<div className="col-12 col-md-6 col-lg-4 mt-3 mt-md-0">
									<a
										href="https://space.jainam.in/#/"
										target="_blank"
										className="btn btn-outline-dark px-4 rounded-3 w-100 py-2 fs-16 in_semi">
										Login
									</a>
								</div>
							</div>
						</form>
					</div>
				</div>
				{/* <div className="offcanvas-footer d-md-block d-none">
          <form className="row p-3" role="search">
            <div className="col-8 col-md-6">
              <a href="https://www.jainam.in/open-demat-account/" target="_blank" className="bg-color29 nav-btn btn px-4 rounded-3 w-100 py-2 text-white">
                Open Demat Account
              </a>
            </div>
            <div className="col-4 col-md-6">
              <a href="https://space.jainam.in/#/" target="_blank" className="btn btn-outline-dark px-4 rounded-3 w-100 py-2 fs-16 in_semi">
                Login
              </a>
            </div>
          </form>
        </div> */}
			</div>
		</>
	);
};

export default Navbar;
