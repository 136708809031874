import React from "react";
import "./sidebar.css";
import "../../assets/sass/global.css";
import useReRouteHook from "../../assets/hooks/useReRouteHook";
import { Categories } from "../../types/types";

interface Props {
  category: Categories;
  category_name: string;
  index: number;
  url_category_name: string;
  url_child_category_name: string;
}

const Sidebar = ({ category, category_name, index, url_category_name, url_child_category_name }: Props) => {

  const isCategoryActive = category_name === url_category_name;
  const isMobile = window.innerWidth < 768; // Adjust breakpoint as needed for your design
  const { reRouteButtonRenderer } = useReRouteHook();
  return (
    <>
      {isCategoryActive && (
        <div
          className={`accordion-item sidebar-accordion-item bw_reg border-0 ${isCategoryActive ? "active" : ""
            }`}
        >
          {isCategoryActive && category && (
            <div
              id={`sideBarCollapse${index}`}
              className={`accordion-collapse sidebar-accordion-collapse ${isCategoryActive && !isMobile ? "show" : "collapse"
                }`}
              data-bs-parent="#sideBarAccordian"
            >
              <div className="sidebar-accordion-body">
                <ul className="list-unstyled">
                  {category?.subcategories &&
                    category?.subcategories?.map((subcategory: any) => {
                      const isThisSubcategoryActive =
                        subcategory?.category_name === url_child_category_name;
                      return (
                        <li
                          key={subcategory.id}
                          className={`py-2 global-font  ${isThisSubcategoryActive ? "active" : ""
                            }`}
                        >
                          {reRouteButtonRenderer({
                            url: `/category/${category_name}/${subcategory?.category_name}`,
                            classes: `decoration fs-16 color28 p-0 text-start bg-white border-0 ${isThisSubcategoryActive ? "in_bold color1" : "in_reg color27"
                              }`,
                            nameText: subcategory.category_name
                          })}
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          )}
        </div >
      )}
    </>
  );
};

export default Sidebar;
