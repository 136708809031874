import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import SubChildContent from "./SubChildContent";
import SubChildHeader from "./SubChildHeader";
import { Link, useLocation, useParams } from "react-router-dom";
import AboutSection from "../aboutsection/AboutSection";
import {
  API_ENDPOINTS,
  SITE_URL,
  toAbsoluteUrl,
} from "../../assets/helpers/AssetHelpers";
interface Category {
  id: string;
  category_name: string;
  parent_cms_category: string | null;
}
interface ApiResponse {
  data: {
    all_category: Category[];
  };
}

interface CmsContent {
  id: string;
  content: string;
}

interface ApiResponse2 {
  data2: any[];
}

const SubChildCategory = () => {
  const { category_name, child_category_name, child } = useParams();
  const formattedChildCategoryName = child_category_name;
  const formattedChildSUBCategoryName = child;
  const [data1, setData1] = useState<ApiResponse>({
    data: {
      all_category: [],
    },
  });
  useEffect(() => {
    fetch(API_ENDPOINTS.getCMSCategory, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
      body: `site=${SITE_URL}`,
    }).then((result) => {
      result.json().then((resp) => {
        setData1(resp);
      });
    });
  }, []);

  const [data2, setData2] = useState<[]>([]);

  useEffect(() => {
    fetch(API_ENDPOINTS.getCMSContent, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
      body: `cms_category=${formattedChildSUBCategoryName}`,
    })
      .then((result) => result.json())
      .then((data) => {
        if (data && data.data) {
          setData2(data.data);
        } else {
          console.error("Invalid API response format:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching CMS content:", error);
      });
  }, []);

  const filteredData = data1?.data?.all_category?.map((item: any) => {
    const subcategories = data2?.filter(
      (content: any) => content.cms_category === item.category_name
    );
    const newItem = {
      ...item,
      subcategories,
    };

    return newItem;
  });
  const mappedData = filteredData?.filter(
    (filterData: any) => filterData?.parent_cms_category === child_category_name
  );

  const childList = mappedData?.filter(
    (item: { category_name: any }) => item?.category_name === child
  );

  const getSubcategories = (parentCategory: any, allCategories: any) => {
    const subcategories = allCategories.filter(
      (category: any) => category.parent_cms_category === parentCategory.id
    );

    if (subcategories.length === 0) {
      return null;
    }

    return subcategories.map((subcategory: any) => ({
      ...subcategory,
      subcategories: getSubcategories(subcategory, allCategories),
    }));
  };
  const nestedCategories = data1?.data?.all_category
    .filter(
      (category) =>
        category.parent_cms_category === null ||
        category.parent_cms_category === ""
    )
    .map((category) => ({
      ...category,
      subcategories: getSubcategories(category, data1?.data?.all_category),
    }));
  const subcategoriesArray = nestedCategories.flatMap(
    (category) => category.subcategories
  );
  const filteredCategory = subcategoriesArray.filter(
    (item: any) => item?.title === child_category_name
  );
  const className =
    process.env.REACT_APP_FULLWIDTH === "1"
      ? "col mx-lg-5 "
      : "col-lg-10 mx-auto";
  return (
    <>
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col m-lg-5">
              <div className="row">
                <div className={className}>
                  <SubChildHeader />
                  <div className="row">
                    <div className="col-lg-3 col-12 mt-lg-2 d-none">
                      <div className="accordion" id="sideBarAccordian">
                        {nestedCategories.map((category: any, index: any) => {
                          return (
                            <>
                              <Sidebar
                                category_name={category.id}
                                category={category}
                                index={index}
                                url_category_name={category_name}
                                url_child_category_name={child_category_name}
                              />
                            </>
                          );
                        })}
                      </div>
                    </div>
                    <div
                      className="col-lg-8 col-12 mt-lg-4 my-4 d-lg-block d-md-block d-none p-1"
                      style={{ width: "100%" }}
                    >
                      <div className=" color3 decoration">
                        <h5 className="py-2 color1 fs-24 in_semi px-2">
                          {formattedChildSUBCategoryName}
                        </h5>
                        {childList.length > 0 ? (
                          <div className="accordion font-title" id="subContent">
                            {childList.map((item: any, index: number) => {
                              return (
                                <>
                                  <SubChildContent
                                    filteredCategory={item}
                                    category_name={category_name}
                                    child_category_name={child_category_name}
                                    child={child}
                                    index={index}
                                  />
                                </>
                              );
                            })}
                          </div>
                        ) : (
                          <div className="border-0 text-center">
                            <img
                              src={toAbsoluteUrl(
                                "about-section/no-faq.svg"
                              )}
                              alt="no_data_found"
                              style={{ width: "200px" }}
                            />
                            <div className="row">
                              <p className="fs-6 py-2 bw_semi col-8 mt-4 mx-auto">
                                To find the information you need, please visit our home page at <Link to="/" className="color1">support.jainam.in</Link> and search for your query. 
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-8 col-12 mt-lg-4 my-4 d-lg-none d-md-none d-block">
                      <div
                        className="mx-lg-2 color3 decoration p-lg-3"
                      >
                        <h5 className="py-2 color1 bw_semi px-lg-2">
                          {formattedChildSUBCategoryName}
                        </h5>
                        {childList.length > 0 ? (
                          <div className="accordion font-title" id="subContent">
                            {childList.map((item: any, index: number) => {
                              return (
                                <>
                                  <SubChildContent
                                    filteredCategory={item}
                                    category_name={category_name}
                                    child_category_name={child_category_name}
                                    index={index}
                                  />
                                </>
                              );
                            })}
                          </div>
                        ) : (
                          <div className="border-0 text-center">
                            <img
                              src={toAbsoluteUrl(
                                "about-section/no-faq.svg"
                              )}
                              alt="no_data_found"
                              style={{ width: "200px" }}
                            />
                            <p className="fs-6 py-2 bw_semi mt-4">
                              To find the information you need, please visit our home page at <a href="support.jainam.in" className="color1">support.jainam.in</a> and search for your query.
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <AboutSection /> */}
      </section>
    </>
  );
};

export default SubChildCategory;
