import React from 'react';
import { toAbsoluteUrl } from '../../assets/helpers/AssetHelpers';
import './style.css';
import { useLocation, useParams } from 'react-router-dom';
const AboutSection = () => {
	const location = useLocation();

	const { childsub } = useParams();
	const conditionallyAction = !childsub;
	const className = process.env.REACT_APP_FULLWIDTH === '1' ? 'col mx-lg-5 ' : 'col-lg-10 mx-auto';
	return (
		<>
			<section
				style={{
					background: 'linear-gradient(to bottom right, #EBFFEA, #EFF9FE, #E4FFFE)',
				}}>
				<div className="container-fluid  d-lg-block d-md-block  my-5">
					<div className="row">
						<div className="col mx-lg-5">
							<div className="row">
								<div className={className}>
									<div className="row py-3 py-md-4 py-lg-0 ">
										<div className="col-lg-8 d-flex align-items-center px-4 px-lg-3">
											<div className="my-0 my-lg-5">
												<div className="bw_semi text-start">
													<div className="color1 fs-42 ur_ebold lh-1 about-text mt-2 mt-lg-0 my-lg-0">
														{conditionallyAction ? 'Do you still feel stuck?' : ''}
													</div>
													<div className="d-lg-none fs-42 color1 ur_ebold lh-1 about-text mt-2 mt-lg-0 my-lg-0">
														{childsub ? 'Do you still feel stuck?' : ''}
													</div>
													<div className="color30 fs-42 ur_ebold mb-lg-4 d-md-block d-none about-text">
														{conditionallyAction
															? 'Have you tried looking for an answer?'
															: 'You still need assistance?'}
													</div>
												</div>
												<div className="my-auto my-0 my-md-4 my-lg-0">
													<div className="d-flex py-3 py-md-0">
														<div className="text-center py-2 me-3">
															<a
																href="https://space.jainam.in/#/"
																className="bg-color29 btn ticket-btn fs-16 in_semi rounded-3 nav-btn px-lg-5 py-lg-3 p-3 py-2 text-nowrap text-white">
																Create a Ticket
															</a>
														</div>
														{/* {conditionallyAction ? ( */}
														<div className="text-center py-2">
															<a
																href="https://space.jainam.in/#/"
																className="color28 bg-transparent ticket-btn rounded-3 btn fs-16 in_semi px-lg-5 py-lg-3 p-3 py-2 border-color28 text-nowrap">
																Track your Ticket
															</a>
														</div>
														{/* ) : (
                              ""
                            )} */}
													</div>
												</div>
											</div>
										</div>
										<div className="col-4 d-flex justify-content-end d-none d-xl-block">
											{/* {location.pathname === "/" ? (
                        <img
                          className="float-end mt-5 pt-4"
                          src={toAbsoluteUrl(
                            "/images/about-section/about-2.svg"
                          )}
                          alt=""
                        />
                      ) : (
                        <img
                          className="float-end"
                          src={toAbsoluteUrl(
                            "/images/about-section/about-banner.svg"
                          )}
                          alt=""
                        />
                      )} */}
											<img
												className="float-end"
												src={toAbsoluteUrl('about-section/about-banner.svg')}
												alt=""
											/>
										</div>
									</div>
								</div>{' '}
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* ============================================== Responsive code ======================================================= */}

			{/* <section>
        <div className="container d-lg-none d-md-none d-block">
          <div className="row py-4 me-4">
            <div
              className="col-lg-11 ms-3  bg-color12 shadow"
              style={{
                borderRadius: "10px",
                padding: "25px 25px",
                marginLeft: "4%",
              }}
            >
              <div className="">
                <div className="bw_semi text-center">
                  <div style={{ fontSize: "14px", color: "#276D7C" }}>
                    Do you still feel stuck?
                  </div>
                  <div style={{ fontSize: "14px", color: "#404341" }}>
                    Have you tried looking for an answer?
                  </div>
                </div>
                <div className="my-auto">
                  <div className="text-center py-3 py-md-0">
                    <a
                      href="https://space.jainam.in/#/"
                      className="btn bw_semi btn-jnm-primary text-decoration-underline fs-16 text-nowrap"
                      style={{ padding: "8px 10px", fontSize: "12px" }}
                    >
                      <img
                        src={toAbsoluteUrl(
                          "/images/about-section/ticket-icon.png"
                        )}
                        alt="Icon"
                        className="me-2"
                        width="15"
                        height="15"
                      />
                      Create a Ticket
                    </a>
                  </div>
                  <div className="text-center py-1 py-md-0">
                    <a
                      href="https://space.jainam.in/#/"
                      className="btn bw_semi btn-jnm-secondary border-color1 text-decoration-underline color1 text-nowrap"
                      style={{ padding: "8px 10px", fontSize: "12px" }}
                    >
                      <img
                        src={toAbsoluteUrl(
                          "/images/about-section/arrow-icon.png"
                        )}
                        alt="Icon"
                        className="me-2"
                        width="15"
                        height="15"
                      />
                      Track your Ticket
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
		</>
	);
};

export default AboutSection;
