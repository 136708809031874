import React, { useState } from "react";
import {
  API_ENDPOINTS,
  SITE_URL,
  toAbsoluteUrl,
} from "../../assets/helpers/AssetHelpers";
import "./subcontent.css";
import { Link } from "react-router-dom";
import useReRouteHook from "../../assets/hooks/useReRouteHook";
import { CmsContent } from "../../types/types";

const SubContent = ({
  filteredCategory,
  category_name,
  child_category_name,
  index,
}: any) => {
  const [filteredData, setFilteredData] = useState<CmsContent[]>([]);
  const [showImage, setShowImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const { reRouteButtonRenderer } = useReRouteHook();

  const handleButtonClick = async (category: string) => {
    setLoading(true);
    try {
      const response = await fetch(API_ENDPOINTS.getCMSContent, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `cms_category=${category}`,
      });

      const fetchedData = await response.json();

      if (fetchedData.data) {
        const filtered = fetchedData.data.filter(
          (item: { cms_category: string }) => item.cms_category === category
        );

        setFilteredData(filtered);
        setLoading(false);
      } else {
        console.error("Unexpected data format:", fetchedData);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  return (
    <>
      <div
        className={`subContent-accordion_item accordion-item `}
        key={index}
        style={{ border: "none" }}
      >
        <h2
          className="subContent-accordion_header accordion-header text-justify in_med"
        >
          <button
            className={`subContent-accordion_button accordion_button_after  b1 accordion-button collapsed subContent-head fs-18 px-lg-2 mb-2 sub-c-sub-style link-hover border-bottom`}
            style={{
              border: "none",
              boxShadow: "none",
              padding: "15px 0px",
            }}
            type="button"
            onClick={() => handleButtonClick(filteredCategory?.category_name)}
            data-bs-toggle="collapse"
            data-bs-target={`#subContentCollapse${index}`}
            aria-expanded="false"
            aria-controls={`subContentCollapse${index}`}
          >
            {filteredCategory?.category_name}
          </button>
        </h2>
        <div
          id={`subContentCollapse${index}`}
          className="subContent-accordion_collapse accordion-collapse collapse"
        // data-bs-parent="#subContent"
        >
          <div className="px-lg-2">
            {loading ? (
              <div className="loader3 py-4">
                <div className="bars bar1"></div>
                <div className="bars bar2"></div>
                <div className="bars bar3"></div>
                <div className="bars bar4"></div>
                <div className="bars bar5"></div>
                <div className="bars bar6"></div>
                <div className="bars bar7"></div>
                <div className="bars bar8"></div>
                <div className="bars bar9"></div>
                <div className="bars bar10"></div>
              </div>
            ) : filteredData && filteredData.length > 0 ? (
              filteredData.map((subItem: CmsContent, subIndex: number) => (
                <ul className="list-unstyled mb-0 mb-md-1" key={subIndex}>
                  <li className="fs-16" style={{ padding: "8px 0" }}>
                    {reRouteButtonRenderer({
                      url: `/category/${encodeURIComponent(
                        category_name
                      )}/${encodeURIComponent(
                        child_category_name
                      )}/${encodeURIComponent(
                        filteredCategory?.category_name
                      )}/${encodeURIComponent(subItem?.id)}`,
                      classes: `color1 cursor-pointer in_med subcategory-hover p-0 fs-18`,
                      nameText: subItem.title.trim(),
                      spanClasses: "color1 subcategory-hover"
                    })}
                  </li>
                </ul>
              ))
            ) : (
              <div className="border-0 text-center">
                {showImage && (
                  <img
                    src={toAbsoluteUrl(
                      "about-section/no-faq.svg"
                    )}
                    alt="no_data_found"
                    style={{ width: "200px" }}
                  />
                )}
                <p className="fs-6 py-2 bw_semi">There are no questions</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SubContent;
