import React from 'react';
import './style.css';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object({
	mobileNumber: Yup.string()
		.matches(/^[0-9]{10}$/, 'Mobile number must be exactly 10 digits')
		.required('Mobile number is required'),
});

const DematSection = () => {
	const className =
		process.env.REACT_APP_FULLWIDTH === '1'
			? 'col m-lg-5 px-3 px-md-4 px-lg-3'
			: 'col-lg-10 mx-auto px-4 px-md-4 px-lg-3';
	return (
		<>
			<section>
				<div className="container-fluid">
					<div className="row">
						<div className="col m-lg-5">
							<div className="row">
								<div className={className}>
									<div className="demat-banner mb-4 p-lg-5">
										<div className="p-md-4 p-3">
											<div className=" text-center row">
												<div className="fs-42 ur_ebold lh-50 text-white mb-3 demat-title">
													Open Your Demat Account Today & Trade Now!!
												</div>
												{/* <div className="fs-20 ur_semi lh-24 text-white mb-3 demat-head">
                          Trusted by 1 Cr+ indians customers with jainam
                        </div>
                        <div className="fs-16 ur_med lh-24 text-white mb-lg-5 mb-4">
                          We provide value with a safe & secured platform
                        </div> */}
												{/* <div className="col-md-6 mx-md-auto ">
                          <div className="row">
                            <div className="col-12 p-lg-0 mb-2 mb-md-4">
                              <div className="input-group py-3 border rounded-3 w-100">
                                <span
                                  className="input-group-text bg-transparent in_med text-white border-0 border-end py-0"
                                  id="basic-addon1"
                                >
                                  +91
                                </span>
                                <input
                                  type="text"
                                  className="form-control bg-transparent text-white in_med bs-none shadow-none border-0 py-0"
                                  placeholder="Enter mobile number"
                                  aria-label="Enter mobile number"
                                  aria-describedby="basic-addon1"
                                />
                              </div>
                            </div>
                            <div className="col-12 p-lg-0">
                              {" "}
                              <a
                                href="https://signup.jainam.in/"
                                className="bg-color31 btn demat-btn fs-16 in_semi px-lg-5 py-3 text-nowrap demat-style-btn w-100"
                              >
                                Open Free Demat Account
                              </a>
                            </div>
                          </div>
                        </div> */}
												<div className="col-md-6 mx-md-auto">
													<div className="text-start">
														<label className="text-white my-2 in_med">Mobile Number</label>{' '}
														<span className="text-danger">*</span>
													</div>
													<Formik
														initialValues={{ mobileNumber: '' }}
														validationSchema={validationSchema}
														onSubmit={(values) => {
															window.location.href = `https://signup.jainam.in/?mobile=${values.mobileNumber}&page_url=https://www.jainam.in/`;
														}}>
														{({ handleSubmit, setFieldValue }) => (
															<Form onSubmit={handleSubmit}>
																<div className="">
																	<div className="col-12 p-lg-0 mb-2 mb-md-4">
																		<div className="input-group py-3 border rounded-3 w-100">
																			<span
																				className="input-group-text bg-transparent in_med text-white border-0 border-end py-0"
																				id="basic-addon1">
																				+91
																			</span>
																			<Field
																				name="mobileNumber"
																				type="text"
																				className="form-control bg-transparent text-white in_med bs-none shadow-none border-0 py-0"
																				placeholder="Enter mobile number"
																				aria-label="Enter mobile number"
																				aria-describedby="basic-addon1"
																				onChange={(e: any) => {
																					const value = e.target.value.replace(/\D/g, '');
																					if (value.length <= 10) {
																						setFieldValue('mobileNumber', value);
																					}
																				}}
																			/>
																		</div>
																		<ErrorMessage
																			name="mobileNumber"
																			component="div"
																			className="text-danger text-start in_semi"
																		/>
																	</div>
																	<div className="col-12 p-lg-0">
																		<button
																			type="submit"
																			className="bg-color31 btn demat-btn fs-16 in_semi px-lg-5 py-3 text-nowrap demat-style-btn w-100">
																			Open Free Demat Account
																		</button>
																	</div>
																</div>
															</Form>
														)}
													</Formik>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default DematSection;
