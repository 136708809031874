import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import { preventCopy, preventContextMenu } from '../../utils/preventCopyPaste';

// const preventCopyPasteOnDivs = () => {
//   const divs = document.querySelectorAll("div");
//   divs.forEach((div) => {
//     div.addEventListener("copy", preventCopy);
//     div.addEventListener("contextmenu", preventContextMenu);
//     div.style.userSelect = "none";
//   });
// };

const AppRoute = () => {
	useEffect(() => {
		const rootElement = document.getElementById('root');
		if (rootElement) {
			rootElement.addEventListener('copy', preventCopy);
			rootElement.addEventListener('contextmenu', preventContextMenu);
			rootElement.style.userSelect = 'none';
		}
	}, []);

	return (
		<BrowserRouter>
			<Routes>
				<Route path='/*' element={<PrivateRoute />} />
			</Routes>
		</BrowserRouter>
	);
};

export default AppRoute;
