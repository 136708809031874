import React, { useEffect, useState } from 'react';
import { Carousel, Row, Col } from 'react-bootstrap';
import useReRouteHook from '../../../assets/hooks/useReRouteHook';
import VideoModal from './VideoModal';
import './VideoSlider.css';
import { toAbsoluteUrl } from '../../../assets/helpers/AssetHelpers';
import { Link, useLocation } from 'react-router-dom';

interface VideoData {
    id: string;
    title: string;
    category: string;
    element_note: string;
    parent: string;
}

interface VideoSliderProps {
	videoData: VideoData[];
	buttonUrl: string;
    onClick?: () => void;
    	isExpanded?: boolean;
}

const VideoSlider: React.FC<VideoSliderProps> = ({ videoData, buttonUrl }) => {
    const { reRouteButtonRenderer } = useReRouteHook();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentVideoDetails, setCurrentVideoDetails] = useState<{ video: string | null; title: string | null; category: string | null } | null>(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isExpanded, setIsExpanded] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0); 
    const location = useLocation();
    const isHomePage = location.pathname === '/';

    const togglePlay = (videoSrc: string, title: string, category: string) => {
        setCurrentVideoDetails({ video: videoSrc, title, category });
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        setCurrentVideoDetails(null);
    };

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const getVisibleVideos = () => {
        if (isExpanded) return videoData.length; 
        if (windowWidth >= 992) return 3; 
        if (windowWidth >= 768) return 2; 
        return 1; 
    };

    const visibleVideos = getVisibleVideos();

    const shouldRenderButton = () => {
        if (windowWidth >= 1200) return videoData.length > 3;
        if (windowWidth >= 577) return videoData.length > 2;
        return videoData.length > 1;
    };

    const handleExpandClick = () => {
        setActiveIndex(0); 
            setIsExpanded(!isExpanded); 
    };

    return (
        <div>
            <div className="slider-container">
                <div className="mx-md-5 mx-4 mt-md-3 mt-2">
                    <Carousel
                        interval={null}
                        indicators={false}
                        controls={!isExpanded && videoData.length > visibleVideos}
                        prevIcon={<img src={toAbsoluteUrl("video-section/arrow-left.svg")} alt="Previous" className="custom-prev-icon" />}
                        nextIcon={<img src={toAbsoluteUrl("video-section/arrow-right.svg")} alt="Next" className="custom-next-icon" />}
                        activeIndex={activeIndex} // Control the active slide
                        onSelect={(selectedIndex) => setActiveIndex(selectedIndex)} // Update index when slide changes
                    >
                        {Array.from({ length: Math.ceil(videoData.length / visibleVideos) }, (_, index) => {
                            const startIndex = index * visibleVideos;
                            const videoChunk = videoData.slice(startIndex, startIndex + visibleVideos);

                            return (
                                <Carousel.Item key={index} className='px-2'>
                                    <Row>
                                        {videoChunk.map((item, subIndex) => {
                                            const iframeMatch = item.element_note?.match(/src="([^"]+)"/);
                                            const videoSrc = iframeMatch ? iframeMatch[1] : null;

                                            return (
                                                <Col key={subIndex} lg={4} md={6} sm={12} className="mb-3">
                                                    {videoSrc ? (
                                                        <div
                                                            onClick={() => togglePlay(videoSrc, item.title, item.category)}
                                                            role="button"
                                                            aria-label="Open video modal"
                                                            className="video-section-iframe-container position-relative"
                                                            style={{ width: '100%', height: 'auto' }}>
                                                            <img
                                                                src={`https://img.youtube.com/vi/${item.id}/maxresdefault.jpg`}
                                                                alt={item.title || 'Video thumbnail'}
                                                                className="rounded-4 responsive-iframe video-height-mobile"
                                                                style={{ width: '100%', display: 'block', filter: 'brightness(80%)' }}
                                                            />
                                                            <img
                                                                src={toAbsoluteUrl('video-section/play-btn.svg')}
                                                                alt="Play Button"
                                                                className="custom-play-button position-absolute start-50 translate-middle"
                                                                role="button"
                                                                aria-label="Play"
                                                            />
                                                        </div>
                                                    ) : (
                                                        <p>Video source not found</p>
                                                    )}
                                                    {/* <Link
                                                        to="/category/Funds/Addition%20of%20funds%20in%20Jainam/Methods%20of%20adding%20fund%20in%20Jainam/how-to-add-funds-using-net-banking-on-comet"
                                                        className="ur_semi color30 mt-1"
                                                        onClick={() => {
                                                            setTimeout(() => {
                                                                const section = document.getElementById('kt_content_container');
                                                                if (section) {
                                                                    const offset = 88;
                                                                    const sectionPosition = section.getBoundingClientRect().top + window.scrollY - offset;
                                                                    window.scrollTo({ top: sectionPosition, behavior: 'smooth' });
                                                                }
                                                            }, 100);
                                                        }}>
                                                        {item.title}
                                                    </Link> */}
                                                    <div className="ur_semi color30 mt-1">{ item.title}</div>
                                                </Col>
                                            );
                                        })}
                                    </Row>
                                </Carousel.Item>
                            );
                        })}
                    </Carousel>

                    {(isHomePage || shouldRenderButton()) && (
                        <div className="d-flex justify-content-end me-3">
                            {reRouteButtonRenderer({
                                url: buttonUrl,
                                classes: 'color1 ur_semi px-0 view-video-btn-border',
                                nameText: isExpanded ? 'View Less' : 'View All Videos',
                                onClick: handleExpandClick, // Call function to reset carousel and expand
                            })}
                        </div>
                    )}
                </div>
            </div>

            <VideoModal
                videoSrc={currentVideoDetails?.video || null}
                isOpen={isModalOpen}
                onClose={handleModalClose}
                title={currentVideoDetails?.title || 'Default Title'}
                category={currentVideoDetails?.category || 'Uncategorized'}
            />
        </div>
    );
};

export default VideoSlider;
