export const socialMediaLinks = [
	{
		href: 'https://t.me/jainamresearch',
		src: 'footer-images/telegram.svg',
		alt: 'Telegram',
	},
	{
		href: 'https://www.instagram.com/jainambroking/',
		src: 'footer-images/instagram.svg',
		alt: 'Instagram',
	},
	{
		href: 'https://twitter.com/JAINAM_BROKING',
		src: 'footer-images/twitter.svg',
		alt: 'Twitter',
	},
	{
		href: 'https://www.facebook.com/JainamBroking/',
		src: 'footer-images/facebook.svg',
		alt: 'Facebook',
	},
	{
		href: 'https://in.linkedin.com/jainamresearch',
		src: 'footer-images/linkedin.svg',
		alt: 'LinkedIn',
	},
	{
		href: 'https://www.youtube.com/channel/UC0_oYkIz8neLnVIjOuKgeSw',
		src: 'footer-images/youtube.svg',
		alt: 'YouTube',
	},
];

export const footerColumns = [
	{
		title: 'Technology',
		links: [
			{ text: 'Jainam Lite', href: 'https://jainam.in/jainam-lite/' },
			{ text: 'Jainam Plus', href: 'https://jainam.in/jainam-plus/' },
			{ text: 'API', href: 'https://www.jainam.in/jainam-api/' },
		],
		subSections: [
			{
				title: 'Partner with Us',
				links: [
					{ text: 'Authorized person (AP)', href: 'https://jainam.in/partner-with-us/' },
					// { text: "Referral Associate", href: "#" },
				],
			},
		],
	},
	{
		title: 'Quick Links',
		links: [
			{ text: 'Investor Charter', href: 'https://jainam.in/investor-charter/' },
			{ text: 'Details of Complaint', href: 'https://jainam.in/details-of-complaint/' },
			{ text: 'Useful Links', href: 'https://jainam.in/useful-links/' },
			{ text: 'Disclosures', href: 'https://jainam.in/disclosures/' },
			{ text: 'Form Download', href: 'https://jainam.in/forms/' },
			{ text: 'Holiday Calendar', href: 'https://jainam.in/nse-holidays/' },
		],
	},
	{
		title: 'Account',
		links: [
			{ text: 'Open an Account', href: 'https://jainam.in/open-demat-account/' },
			{ text: 'Fund Transfer', href: 'https://space.jainam.in/#/' },
		],
	},
	{
		title: 'Community',
		links: [
			{ text: 'Ho Growth', href: 'https://hogrowth.com/' },
			{ text: 'FAQs', href: 'https://support.jainam.in/' , isLocalPage:'/'},
			{ text: 'Glossary', href: 'https://jainam.in/jainam-glossary/' },
			{ text: 'Blogs', href: 'https://jainam.in/blog/' },
		],
	},
	{
		title: 'Company',
		links: [
			{ text: 'About', href: 'https://jainam.in/about-us/' },
			{ text: 'Pricing', href: 'https://jainam.in/pricing/' },
			{ text: 'Events', href: 'https://events.jainam.in/#/home' },
			{ text: 'Careers', href: 'https://jainam.in/career/' },
			{ text: 'Contact Us', href: 'https://jainam.in/contact-us/' },
		],
	},
];

export const links = [
	{ href: 'https://jainam.in/disclaimer/', text: 'Disclaimer' },
	{ href: 'https://jainam.in/privacy-policy/', text: 'Risk Disclosure' },
	{ href: 'https://jainam.in/terms-conditions/', text: 'Privacy Policy' },
	{ href: 'https://jainam.in/terms-conditions/', text: 'Terms & Condition' },
	{ href: 'https://jainam.in/contact-us/#tab2', text: 'Grievances and Complaints' },
];
export const sliderData = [
	{
		title: 'Jainam IFSC Mavens Private Limited (CIN: U65990GJ2017PTC097096)',
		description: `
      Unit-235, 2 Floor, Signature Tower, Block - 13, Road 1C, Zone-1, GIFT SEZ, 
      GIFT City, Gandhinagar, Gujarat – 382355 | Corporate Office: Jainam House, 
      Plot No. 42, Near Shardayatan School, Piplod, Surat, Gujarat – 395007 | 
      SEBI Registration No.: INZ000179836 | Member: NSE IFSC Limited - Trading ID: 10036
    `,
	},
	{
		title:
			'Jainam Broking Limited (Erstwhile Jainam Share Consultants Private Limited) (CIN: U67120GJ2003PLC043162)',
		description: `
      P03-02 C / D / E, 3rd Floor, WTC Tower (51A), Road 5E, Block 51, Zone 5, DTA, 
      GIFT City, Gandhinagar, Gujarat – 382355 | Corporate Office: Jainam House, Plot 
      No. 42, Near Shardayatan School, Piplod, Surat, Gujarat – 395007 | Tel No.: 0261 6725555 / 2305555 | 
      Website: www.jainam.in | SEBI Registration No.: INZ000198735 Member: NSE - Trading ID: 12169 | 
      BSE - Trading ID: 2001 | MCX - Trading ID: 56670 | NCDEX - Trading ID: F01297 | 
      CDSL Depository through Jainam Broking Limited : CDSL SEBI Regn No.: IN-DP-CDSL-223-2016 - DP ID: 041500 
      Dated: 15 December 2005 | Research Analyst: INH000006448 | PMS Regn: INP000006785 | 
      ARN: 39208 AMFI-Registered Mutual Fund Distributor, Since 21-05-2006. Valid Upto: 25-09-2026
    `,
	},
	{
		title: 'Jainam Commodities Private Limited (CIN: U51100GJ2005PTC046161)',
		description: `
      Registered Address: M-11, Malhar Complex, Dumas Road, Surat, Gujarat-395007| Corporate Office: 
      Jainam House, Plot . NO. 42, Near Shardayatan School, Piplod, Surat, Gujarat - 395007| 
      Tel No: 0261 6725555 / 2305555 | Website: www.jainam.in | SEBI registration No: INZ000091934 | 
      Member: NCDEX - Trading ID: 00738
    `,
	},
];
export const footerData = [
	{
		content: `Customers having any query / feedback / clarification may write to customer.care@jainam.in`,
	},
	{
		content: `Customers having any grievances or complaints may write to grievance@jainam.in`,
	},
	{
		content: `Jainam Broking Limited involves in proprietary trading alongside its clientele business`,
	},
	{
		content: `Jainam Commodities Private Limited is involved in proprietary trading with MCX & NCDEX in addition to clientele business.`,
	},
	{
		content: `Investors do not need to issue cheque while subscribing to an IPO. Bank account number and an application form authorizing your bank to make payment in case of allotment fulfills the requirement. No worries for refund as the money remains in investor's account.`,
	},
	{
		content: `Please ensure that you carefully read the Risk Disclosure Document as prescribed by SEBI, our Terms & Conditions and Privacy Policy.`,
	},
	{
		content: `Procedure to file a complaint on SEBI SCORES: Register on SCORES portal. Mandatory details for filing complaints on SCORES: Name, PAN, Address, Mobile Number, E-mail ID. Benefits: Effective Communication, Speedy redressal of the grievances.`,
	},
	{
		content: `Prevent Unauthorized Transactions in your Demat account - Update your mobile number with your depository participant. Receive alerts on your registered mobile for all the transactions in your account directly from CDSL on the same day. Issued in interest of investors.`,
	},
	{
		content: `KYC is one time exercise while dealing in securities market - once the KYC is done through a SEBI registered intermediary (Broker, DP, Mutual Fund etc.), Investor does not need to repeat the procedure when he/she approaches another intermediary.`,
	},
	{
		content: `Attention Investors: 
    (1) Stock Brokers can accept securities as margin from clients only by way of pledge in the depository system w.e.f. September 1, 2020. 
    (2) Update your mobile number & Email ID with your stock broker / depository participant and receive OTP directly from depository on your email ID and/or mobile number to create pledge. 
    (3) Pay 20% upfront margin of the transaction value to trade in cash market segment. 
    (4) Investors may please refer to the Exchange's Frequently Asked Questions (FAQs) issued vide circular reference NSE/INSP/45191 dated July 31, 2020 and NSE/INSP/45534 dated August 31, 2020 and other guidelines issued from time to time in this regard. 
    (5) Check your Securities / MF / Bonds in the consolidated account statement issued by NSDL/CDSL every month.`,
	},
];
