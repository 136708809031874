import React from "react";
import { toAbsoluteUrl } from "../../assets/helpers/AssetHelpers";

interface SocialMediaLinkProps {
  href: string;
  src: string;
  alt: string;
}

const SocialMediaLink: React.FC<SocialMediaLinkProps> = ({ href, src, alt }) => (
  <li className="list-inline-item">
    <a href={href} className="rounded decoration color1 cursor-pointer">
      <img src={toAbsoluteUrl(src)} className="img-fluid me-1" style={{ width: "26px" }} alt={alt} />
    </a>
  </li>
);

export default SocialMediaLink;
