import React from 'react'
import useReRouteHook from '../../assets/hooks/useReRouteHook';

const VideoHeader = () => {
	const { reRouteButtonRenderer } = useReRouteHook();

  return (
    <div>
      <div className="d-flex pt-2 px-0">
        <div className="col-lg-12 col-12">
          <div className="align-items-center d-md-flex justify-content-between">
            <nav aria-label="breadcrumb" className="px-0 px-md-3 px-lg-0">
              <ol className="breadcrumb cursor-pointer in_reg fs-sm-14 m-0">
                <li className="breadcrumb-item active" aria-current="page">
                  {reRouteButtonRenderer({
                    url: 'https://www.jainam.in/',
                    classes: 'color1 in_reg ps-0',
                    nameText: 'Home',
                  })}
                </li>
                <li className="breadcrumb-item">
                  {reRouteButtonRenderer({
                    url: `/`,
                    classes: 'color1 in_reg',
                    nameText: "Support",
                  })}
                </li>
                <li className="breadcrumb-item">
                  {reRouteButtonRenderer({
                    url: `/videos`,
                    classes: 'color1 in_reg',
                    nameText: "Video Tutorials",
                  })}
                </li>
              </ol>
            </nav>
          
          </div>
        </div>
		  </div>
    </div>
  )
}

export default VideoHeader
