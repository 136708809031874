import React from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { toAbsoluteUrl } from "../../assets/helpers/AssetHelpers";
import useReRouteHook from "../../assets/hooks/useReRouteHook";

const SubChildHeader = () => {
  const { category_name, child_category_name, child } = useParams();
  const navigate = useNavigate();

  const { reRouteButtonRenderer } = useReRouteHook();
  if (!category_name || !child_category_name || !child) {
    navigate('/no-results-found');
    return null;
  }
  return (
    <div className="d-flex pt-4 px-0">
      <div className="col-lg-12 col-12">
        <nav aria-label="breadcrumb" className="px-0 px-md-3 px-lg-0">
          <ol className="breadcrumb cursor-pointer in_reg fs-sm-14">
            <li className="breadcrumb-item active" aria-current="page">
              {reRouteButtonRenderer({
                url: "/",
                classes: "ps-0 color1 in_reg border-0 bg-transparent",
                nameText: "Home"
              })}
            </li>
            <li className="breadcrumb-item">
              {reRouteButtonRenderer({
                url: `/category/${category_name}/`,
                classes: "color1 in_reg border-0 bg-transparent",
                nameText: category_name
              })}
            </li>
            <li className="breadcrumb-item">
              {reRouteButtonRenderer({
                url: `/category/${category_name}/${child_category_name}`,
                classes: "color1 in_reg border-0 bg-transparent",
                nameText: child_category_name
              })}
            </li>
            <li className="breadcrumb-item">
              {reRouteButtonRenderer({
                url: `/category/${category_name}/${child_category_name}/${child}`,
                classes: "color1 in_reg border-0 bg-transparent",
                nameText: child
              })}
            </li>
          </ol>
        </nav>
      </div>
    </div>
  );
};

export default SubChildHeader;
