import React, { useEffect, useState } from 'react';
import { API_ENDPOINTS, toAbsoluteUrl } from '../../assets/helpers/AssetHelpers';
import './VideoSection.css';
import VideoSlider from '../common/VideoSlider/VideoSlider';
import { CmsVideo } from '../../types/types';

const VideoSection = () => {
	const [sliderVideoData, setSliderVideoData] = useState([]);

	useEffect(() => {
    const fetchVideoData = async () => {
        try {
            const response = await fetch(API_ENDPOINTS.getVideo);
            if (!response.ok) {
                throw new Error(`Error: ${response.status}`);
            }
            const data = await response.json();

            const allCategories = data.data;

            const allVideos = allCategories.reduce((acc: any, category: any) => {
                if (category.cms_videos && category.cms_videos.length > 0) {
                    acc.push(...category.cms_videos);
                }
                return acc;
            }, []);

            const cmsVideoData = allVideos.map((video: CmsVideo) => {
                const videoTags: string[] = video.element_note.split(' ');
                const srcTag: string = videoTags.find((tag: string) => tag.includes('src')) || '';
                const videoUrlTagsArray: string[] = srcTag.split('/');
                const videoId = videoUrlTagsArray[videoUrlTagsArray.length - 1].split('?')[0];

                return {
                    id: videoId,
                    element_note: video.element_note,
                    parent: video.parent,
                    title: video.video_title,
                    category: video.video_category,
										show_video_on_main_page: video.show_video_on_main_page,
										rootPageVideoRank: video.main_video_rank !== undefined ? Number(video.main_video_rank) : null,
										modified: video.modified,
									};
          	  });

							const sortedVideos = cmsVideoData.sort((a: { show_video_on_main_page: number; rootPageVideoRank: any; modified: string | number | Date; }, b: { show_video_on_main_page: number; rootPageVideoRank: any; modified: string | number | Date; }) => {
								if (a.show_video_on_main_page === 1 && b.show_video_on_main_page === 0) {
										return -1; 
								}
								if (a.show_video_on_main_page === 0 && b.show_video_on_main_page === 1) {
										return 1; 
								}
								if (a.show_video_on_main_page === 1 && b.show_video_on_main_page === 1) {
									const rankA = a.rootPageVideoRank === 0 ? Infinity : a.rootPageVideoRank;
									const rankB = b.rootPageVideoRank === 0 ? Infinity : b.rootPageVideoRank;
									return rankA - rankB;
							}
								if (a.show_video_on_main_page === 0 && b.show_video_on_main_page === 0) {
										return new Date(b.modified).getTime() - new Date(a.modified).getTime();
								}
								return 0;
						});
						
            setSliderVideoData(sortedVideos);
        } catch (error) {
            console.error('Failed to fetch video data:', error);
        }
    };

    fetchVideoData();
}, []);

	return (
		<section id="videoSection">
			<div className="container-fluid">
				<div className="row">
					<div className="col px-lg-5">
						<div className="row">
							<div className="col-lg-10 mx-auto px-4 px-md-4 px-lg-4">
								{sliderVideoData.length > 0 ? (
									<>
										{/* <div className="ur_semi color38 video-section-subtitle">Tutorials</div> */}
										<div className="ur_ebold color30 fs-28 video-section-title">Video Tutorials</div>
										<div>
											<VideoSlider videoData={sliderVideoData} buttonUrl="/videos" />
										</div>
									</>
								) : null}
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default VideoSection;
