import React, { useEffect, useRef, useState } from 'react';
import Sidebar from '../MainCategory/Sidebar';
import { API_ENDPOINTS, SITE_URL, toAbsoluteUrl } from '../../assets/helpers/AssetHelpers';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import './childcategory.scss';
import ChildHeader from './ChildHeader';
import MainSidebar from '../MainCategory/Sidebar';
import './childcategory.scss';
import { Image } from 'lightbox.js-react';
import 'lightbox.js-react/dist/index.css';
import useReRouteHook from '../../assets/hooks/useReRouteHook';

interface Category {
	id: string;
	category_name: string;
	parent_cms_category: string | null;
}
interface ApiResponse {
	data: {
		all_category: Category[];
	};
}
interface CmsContent {
	content_type: string;
	attachment: string | undefined;
	id: string;
	content: string;
}

interface ApiResponse2 {
	data2: {
		all_content: any[];
	};
}
type SubFilteredData = {
	data?: {
		page_title?: string;
		meta_title?: string;
		description?: string;
		hindi_available?: boolean;
	};
};
const ChildCategory = () => {
	const { reRouteButtonRenderer } = useReRouteHook();
	const [language, setLanguage] = useState<string>('');

	const [data1, setData1] = useState<ApiResponse>({
		data: {
			all_category: [],
		},
	});
	useEffect(() => {
		fetch(API_ENDPOINTS.getCMSCategory, {
			method: 'POST',
			headers: new Headers({
				'Content-Type': 'application/x-www-form-urlencoded',
			}),
			body: `site=${SITE_URL}`,
		}).then((result) => {
			result.json().then((resp) => {
				setData1(resp);
			});
		});
	}, []);

	const [selectedRelatedArticle, setSelectedRelatedArticle] = useState<string | null>(null);

	const { category_name, child_category_name, child, childsub } = useParams();

	const getSubcategories = (parentCategory: any, allCategories: any) => {
		const subcategories = allCategories.filter(
			(category: any) => category.parent_cms_category === parentCategory.id,
		);

		if (subcategories.length === 0) {
			return null;
		}
		return subcategories.map((subcategory: any) => ({
			...subcategory,
			subcategories: getSubcategories(subcategory, allCategories),
		}));
	};
	const nestedCategories = data1?.data?.all_category
		.filter(
			(category: any) =>
				category.parent_cms_category === null || category.parent_cms_category === '',
		)
		.map((category: any) => ({
			...category,
			subcategories: getSubcategories(category, data1?.data?.all_category),
		}));

	const navigate = useNavigate();

	const handleRelatedArticleClick = (relatedArticleTitle: string) => {
		setSelectedRelatedArticle(relatedArticleTitle);
	};

	const [dataLoaded, setDataLoaded] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => {
			setDataLoaded(true);
		}, 200);

		return () => clearTimeout(timer);
	}, []);

	const [subFilteredData, setSubFilteredData] = useState<SubFilteredData>({});
	useEffect(() => {
		if (subFilteredData?.data) {
			const { page_title, meta_title, description } = subFilteredData.data;

			if (page_title) {
				document.title = page_title;
			}

			if (meta_title) {
				const metaTitleTag = document.querySelector("meta[name='title']") || createMetaTag('title');
				metaTitleTag.setAttribute('content', meta_title);
			}

			if (description) {
				const metaDescriptionTag =
					document.querySelector("meta[name='description']") || createMetaTag('description');
				metaDescriptionTag.setAttribute('content', description);
			}
		}
		return () => {
			document.title = 'Jainam Support';
			const metaTitleTag = document.querySelector("meta[name='title']");
			const metaDescriptionTag = document.querySelector("meta[name='description']");

			if (metaTitleTag) metaTitleTag.setAttribute('content', '');
			if (metaDescriptionTag) metaDescriptionTag.setAttribute('content', '');
		};
	}, [subFilteredData]);

	const createMetaTag = (name: string) => {
		const tag = document.createElement('meta');
		tag.setAttribute('name', name);
		document.head.appendChild(tag);
		return tag;
	};

	useEffect(() => {
		fetch(API_ENDPOINTS.getCMSPage, {
			method: 'POST',
			headers: new Headers({
				'Content-Type': 'application/x-www-form-urlencoded',
			}),
			body: `faq=${encodeURIComponent(childsub || '')}${
				language ? `&content=${encodeURIComponent(language)}` : ''
			}`,
		}).then((result) => {
			result.json().then((resp) => {
				setSubFilteredData(resp);
			});
		});
	}, [childsub, language]);

	function toggleNavbar(event: { currentTarget: any }) {
		const button = event.currentTarget;
		const isExpanded = button.getAttribute('aria-expanded') === 'false';

		if (isExpanded) {
			button.setAttribute('data-bs-state', 'closed');
		} else {
			button.setAttribute('data-bs-state', 'open');
		}
	}
	const className =
		process.env.REACT_APP_FULLWIDTH === '1' ? 'col mx-lg-5 ' : 'col-lg-10 mx-auto px-4 px-lg-3';

	if (!category_name || !child_category_name) {
		navigate('/no-results-found');
		return null;
	}

	return (
		<>
			<section>
				<div className="container-fluid d-lg-block d-md-block">
					<div className="row">
						<div className="col m-lg-5">
							<div className="row">
								<div className={className}>
									<div className="d-flex">
										<div className="col-lg-12">
											<ChildHeader
												language={language}
												setLanguage={setLanguage}
												hindiAvailable={!!subFilteredData?.data?.hindi_available}
											/>
										</div>
									</div>

									<div className="row">
										<div className="col-lg-3 mt-3 d-none d-lg-block p-lg-0">
											<div className="accordion" id="sideBarAccordian">
												{nestedCategories.map((category: any, index: any) => {
													return (
														<>
															<Sidebar
																category_name={category.id}
																category={category}
																index={index}
																url_category_name={category_name}
																url_child_category_name={child_category_name}
															/>
														</>
													);
												})}
											</div>
										</div>
										<nav className="navbar navbar-expand-lg  d-block d-lg-none">
											<div className="container-fluid justify-content-start px-3 px-md-4 px-lg-3">
												<button
													className="navbar-toggler border-0 p-0 pe-3"
													type="button"
													data-bs-toggle="collapse"
													data-bs-target="#navbarNavAltMarkup"
													aria-controls="navbarNavAltMarkup"
													aria-expanded="false"
													aria-label="Toggle navigation"
													onClick={toggleNavbar}>
													<span className="navbar-toggler-icon"></span>
												</button>
												<a className="navbar-brand in_reg fs-18 lh-24" href="#">
													View all categories
												</a>
												<div className="collapse navbar-collapse" id="navbarNavAltMarkup">
													<div className="accordion my-4" id="sideBarAccordian">
														{nestedCategories.map((category: any, index: any) => {
															return (
																<>
																	<MainSidebar
																		category_name={category.id}
																		category={category}
																		index={index}
																		url_category_name={category_name}
																		url_child_category_name={child_category_name}
																	/>
																</>
															);
														})}
													</div>
												</div>
											</div>
										</nav>
										{dataLoaded ? (
											<div className="col-lg-9 mt-3">
												{selectedRelatedArticle === null && (
													<div className="border-bottom htmlcontent mt-1">
														{Object.values(subFilteredData).map(
															(subItem: any, subIndex: number) => {
																return (
																	<div key={subIndex}>
																		{(language === 'hindi'
																			? subItem.cms_content_hindi_table
																			: subItem.cms_content_table
																		)?.map((item: any, index: number) => {
																			return (
																				<div key={index}>
																					{/* Display Title and Subtitle */}
																					<div
																						className={
																							index === 0 ? 'child-cat-title' : 'child-cat-subtitle'
																						}
																						dangerouslySetInnerHTML={{
																							__html:
																								(language === 'hindi'
																									? item.content_type_hindi
																									: item.content_type) !== 'Important / Update'
																									? language === 'hindi'
																										? item.editor_hindi
																										: item.content || item.editor
																									: '',
																						}}
																					/>

																					{/* Handle Video Content */}
																					{(language === 'hindi'
																						? item.content_type_hindi
																						: item.content_type) === 'Video' &&
																						(language === 'hindi'
																							? item.element_note_hindi
																							: item.element_note) && (
																							<p className="text-center">
																								<>
																									{(() => {
																										const iframeMatch = (
																											language === 'hindi'
																												? item.element_note_hindi
																												: item.element_note
																										).match(/src="([^"]+)"/);
																										const videoSrc = iframeMatch
																											? iframeMatch[1]
																											: null;

																										return videoSrc ? (
																											<div className="iframe-container">
																												<iframe
																													width="100%"
																													height="315"
																													src={`${videoSrc}?&rel=0`}
																													title="YouTube video player"
																													frameBorder="0"
																													allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
																													referrerPolicy="strict-origin-when-cross-origin"
																													allowFullScreen
																													className="rounded-3 responsive-iframe"></iframe>
																											</div>
																										) : (
																											<p>Video source not found</p>
																										);
																									})()}
																								</>
																							</p>
																						)}

																					{/* Handle Image Content */}
																					{(language === 'hindi'
																						? item.content_type_hindi
																						: item.content_type) === 'Image' && (
																						<p className="text-center">
																							<>
																								<Image
																									image={{
																										src:
																											language === 'hindi'
																												? `${process.env.REACT_APP_MEDIA_URL}${item.attachment}`
																												: `${process.env.REACT_APP_MEDIA_URL}${item.attachment}`,
																									}}
																									fullScreen={true}
																									className="lightbox-image my-3"
																								/>
																							</>
																						</p>
																					)}

																					{/* Handle Video Attachments */}
																					{/* {item.content_type === 'Video' &&
																									item.attachment && (
																										<p className="text-center">
																											<>
																												<video
																													controls
																													className="video-js rounded-3 video-height my-4">
																													<source
																														src={item.attachment}
																														type="video/mp4"
																													/>
																												</video>
																											</>
																										</p>
																									)} */}
																					{(language === 'hindi'
																						? item.content_type_hindi
																						: item.content_type) === 'Video' &&
																						item.attachment && (
																							<p className="text-center">
																								<>
																									<video
																										controls
																										className="video-js rounded-3 video-height my-4"
																										// onCanPlayThrough={(event) => {
																										// 	event.target.play(); // Automatically play the video once it's fully loaded
																										// }}
																										onLoadStart={() =>
																											console.log('Video is starting to load')
																										}
																										onWaiting={() =>
																											console.log('Video is buffering')
																										}
																										onLoadedData={() =>
																											console.log('Video data has been loaded')
																										}
																										onError={(e) =>
																											console.error('Error loading video', e)
																										}>
																										<source
																											src={
																												language === 'hindi'
																													? `${process.env.REACT_APP_MEDIA_URL}${item.attachment}`
																													: `${process.env.REACT_APP_MEDIA_URL}${item.attachment}`
																											}
																											type={
																												item.attachment.endsWith('.webm')
																													? 'video/webm'
																													: 'video/mp4'
																											}
																										/>
																									</video>
																								</>
																							</p>
																						)}

																					{/* Handle PDF Content */}
																					{(language === 'hindi'
																						? item.content_type_hindi
																						: item.content_type) === 'PDF' && (
																						<>
																							<a
																								className="in_med color1 fs-18"
																								href={
																									language === 'hindi'
																										? `${process.env.REACT_APP_MEDIA_URL}${item.attachment}`
																										: `${process.env.REACT_APP_MEDIA_URL}${item.attachment}`
																								}
																								target="_blank"
																								rel="noreferrer">
																								{item.element_note ? item.element_note : 'PDF'}
																							</a>
																						</>
																					)}

																					{/* Handle Important/Update Content */}
																					{(language === 'hindi'
																						? item.content_type_hindi
																						: item.content_type) === 'Important / Update' && (
																						<>
																							<div>
																								<table className="table announcement-table my-3 border-0">
																									<thead>
																										<tr>
																											<th
																												scope="col"
																												className="faq-announcement-table-title border-0">
																												Important/ Updates
																											</th>
																										</tr>
																									</thead>
																									<tbody>
																										<tr>
																											<th
																												scope="row"
																												className="faq-announcement-table-content border-0">
																												<ul>
																													{(language === 'hindi'
																														? item.content_hindi
																														: item.content
																													)
																														?.split('\n')
																														.map((line: string, index: number) => (
																															<li key={index}>{line}</li>
																														))}
																												</ul>
																											</th>
																											{/* <th
																												scope="row"
																												className="faq-announcement-table-content border-0"
																												// dangerouslySetInnerHTML={{
																												// 	__html: language === 'hindi' ? item.content_hindi : item.content
																												// }}
																												dangerouslySetInnerHTML={{
																													__html: language === 'hindi' ? item.html_editor : item.html_editor
																												}}
																											></th> */}
																										</tr>
																									</tbody>
																								</table>
																							</div>
																						</>
																					)}
																				</div>
																			);
																		})}
																	</div>
																);
															},
														)}
													</div>
												)}

												<div className="float-end py-2 d-none">
													Did you find it helpful?
													<img
														src={toAbsoluteUrl('child-content/like.png')}
														className="me-2 ms-2"
														alt="Like_image"
													/>
													<img
														src={toAbsoluteUrl('child-content/dislike.png')}
														alt="DisLike_image"
													/>
												</div>

												{Object.values(subFilteredData).map((item: any) => {
													if (item.cms_related_cms_page && item.cms_related_cms_page.length > 0) {
														return (
															<React.Fragment key={item.id}>
																<h2 className="py-2 bw_reg font1 mt-4" style={{ fontSize: '22px' }}>
																	Related articles
																</h2>
															</React.Fragment>
														);
													}
													return null;
												})}

												<div className="row">
													<div className="col-12">
														<ul className="px-0">
															{Object.values(subFilteredData).map((item: any) => {
																return (
																	item.cms_related_cms_page &&
																	item.cms_related_cms_page.map((relatedArticle: any) => (
																		<li
																			key={relatedArticle.id}
																			className="list-unstyled bw_med"
																			style={{ padding: '8px 0' }}>
																			{reRouteButtonRenderer({
																				url: `/category/${category_name}/${child_category_name}/${relatedArticle.cms_category}/${relatedArticle.id}`,
																				classes: 'color1 cursor-pointer in_med subcategory-hover',
																				nameText:
																					selectedRelatedArticle === null && relatedArticle.title,
																			})}
																			{selectedRelatedArticle === relatedArticle.title && (
																				<>
																					{relatedArticle?.cms_content_table?.map(
																						(contentItem: any, index: any) => (
																							<div
																								key={index}
																								dangerouslySetInnerHTML={{
																									__html: contentItem?.content,
																								}}
																							/>
																						),
																					)}
																				</>
																			)}
																		</li>
																	))
																);
															})}
														</ul>
													</div>
												</div>
											</div>
										) : (
											<div className="col-lg-8">
												<div className="parent-loader">
													<div style={{ height: '150px' }}></div>
													<div className="loader3">
														<div className="bars bar1"></div>
														<div className="bars bar2"></div>
														<div className="bars bar3"></div>
														<div className="bars bar4"></div>
														<div className="bars bar5"></div>
														<div className="bars bar6"></div>
														<div className="bars bar7"></div>
														<div className="bars bar8"></div>
														<div className="bars bar9"></div>
														<div className="bars bar10"></div>
													</div>
												</div>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default ChildCategory;
