import React, { useEffect, useState } from "react";
import "./style.css";
import { API_ENDPOINTS, SITE_URL } from "../../assets/helpers/AssetHelpers";
import { useNavigate, useParams } from "react-router-dom";

interface Category {
  id: string;
  category_name: string;
  parent_cms_category: string | null;
}

interface ApiResponse {
  data: {
    all_category: Category[];
  };
}

const ParentCard = () => {
  const navigate = useNavigate();
  const { category_name: paramCategoryName } = useParams();

  const [data1, setData1] = useState<ApiResponse>({
    data: {
      all_category: [],
    },
  });

  const fetchallcategory = () => {
    fetch(API_ENDPOINTS.getCMSCategory, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
      body: `site=${SITE_URL}`,
    }).then((result) => {
      result.json().then((resp) => {
        setData1(resp);
      });
    });
  };

  useEffect(() => {
    fetchallcategory();
  }, []);

  const getSubcategories = (
    parentCategory: Category,
    allCategories: Category[]
  ): Category[] => {
    const subcategories = allCategories.filter(
      (category) => category.parent_cms_category === parentCategory.id
    );

    return subcategories.map((subcategory) => ({
      ...subcategory,
      subcategories: getSubcategories(subcategory, allCategories),
    }));
  };

  const nestedCategories = data1?.data?.all_category
    ?.filter(
      (category) =>
        category.parent_cms_category === null ||
        category.parent_cms_category === ""
    )
    .map((category) => ({
      ...category,
      subcategories: getSubcategories(category, data1?.data?.all_category),
    }));

  const handleNavigate = (categoryId: string) => {
    navigate(`/category/${categoryId}/`);
  };
  return (
    <div className="d-none d-lg-block">
      <div className="d-flex mt-4 horizontal-scroll">
        {nestedCategories.map((category) => {
          const isActive = category.category_name === paramCategoryName;
          const className = isActive
            ? "fs-14 in_bold lh-22 border-color1 color1 border rounded-3 p-3 active-bg me-3"
            : "fs-14 in_med border-secondary-subtle bg-transparent lh-22 color28 border rounded-3 p-3 me-3";

          return (
            <a
              key={category.id}
              onClick={() => handleNavigate(category.id)}
              className={className}
              style={{ cursor: "pointer" }}
            >
              {category.category_name}
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default ParentCard;
