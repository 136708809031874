import { useNavigate } from 'react-router-dom';

const useReRouteHook = () => {
	const navigate = useNavigate();

	const reRouteButtonRenderer = ({
		classes = '',
		nameText,
		url,
		endAdornment,
		startAdornment,
		spanClasses = '',
		onClick,
	}: {
		url: string;
		classes?: string;
		nameText: string;
		endAdornment?: React.JSX.Element;
		startAdornment?: React.JSX.Element;
		spanClasses?: string;
		onClick?: () => void;
	}) => {
		return (
			<button
				onClick={() => {
					if (onClick) onClick();

					if (url === 'https://www.jainam.in/') {
						window.location.href = url;
					} else if (url !== '#') {
						navigate(url);
						if (url === '/videos') {
							window.scrollTo({ top: 0, behavior: 'smooth' });
						}
						setTimeout(() => {
							const section = document.getElementById('kt_content_container');
							if (section) {
								const offset = 88;
								const sectionPosition =
									section.getBoundingClientRect().top + window.scrollY - offset;
								window.scrollTo({ top: sectionPosition, behavior: 'smooth' });
							}
						}, 100);
					} else {
						navigate(url);
					}
				}}
				className={`bg-transparent border-0 ${classes}`}>
				<div className="d-flex justify-content-between align-items-center w-100">
					{startAdornment && <div className="pe-2">{startAdornment}</div>}
					<span className={`text-start ${spanClasses}`}>{nameText}</span>
					{endAdornment && <div>{endAdornment}</div>}
				</div>
			</button>
		);
	};

	return { reRouteButtonRenderer };
};

export default useReRouteHook;
