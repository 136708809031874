import React, { useEffect, useRef } from 'react';
import FooterColumn from './FooterColumn';
import SocialMediaLink from './SocialMediaLink';

import './footer.css';
import { toAbsoluteUrl } from '../../assets/helpers/AssetHelpers';

import Slider from 'react-slick';
import { socialMediaLinks, footerColumns, links, sliderData, footerData } from './footerConstants';
const Footer = () => {
	const target = '_self';
	let sliderRef = useRef(null);

	const settings = {
		dots: false,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		speed: 2500,
		autoplay: true,
		autoplaySpeed: 4000,
		arrows: false,
	};
	useEffect(() => {
		const img = new Image();
		img.src = process.env.PUBLIC_URL + 'footer-images/footer-logo.webp';
		img.onload = () => {
			const footerLogo = document.querySelector('#footer-logo') as HTMLImageElement | null;
			if (footerLogo) {
				footerLogo.src = img.src;
			}
		};
	}, []);
	const className =
		process.env.REACT_APP_FULLWIDTH === '1'
			? 'col m-lg-5 my-4 px-3 px-md-4 px-lg-3'
			: 'col-lg-9 mx-auto px-4 px-md-4 px-lg-3 my-4';
	return (
		<>
			<section style={{ background: '#F8F8F8' }}>
				{/* <footer className="bd-footer "> */}
				<div className="container-fluid " style={{ overflowX: 'hidden' }}>
					<div className="row">
						<div className="col my-5">
							<div className="row">
								<div className={className}>
									<div className="row justify-content-between">
										<div className="col-lg-3 mb-3">
											<div className="w-75 custom-footer-width">
												<div className="row">
													<div className="col-md-8 col-lg-12">
														<a
															className="d-inline-flex align-items-center mb-2 text-body-emphasis text-decoration-none"
															href="/"
															aria-label="Bootstrap">
															<img
																src={toAbsoluteUrl("footer-images/footer-logo.svg")}
																alt=""
																className="img-fluid w-100"
															/>
														</a>
														<div className="color28 in_med fs-16 my-3">
															Registered Address: M-11, Malhar Complex, Dumas Road, Surat, Gujarat- 395007
														</div>
													</div>
													<div className="col-md-4 col-lg-12">
														<div className="color28 lh-24 fs-16 in_semi">FOLLOW US ON</div>
														<ul className="d-flex justify-content-lg-between list-unstyled mt-3 my-3">
															{socialMediaLinks.map((link, index) => (
																<SocialMediaLink key={index} href={link.href} src={link.src} alt={link.alt} />
															))}
														</ul>
														<div className="row my-lg-0 my-4">
															<div className="col-md-6 col-4">
																<a href="https://play.google.com/store/apps/details?id=com.codifi.jainam&hl=en_US&pli=1">
																	<img
																		className="w-100"
																		src={toAbsoluteUrl("footer-images/googlePlay.svg")}
																		alt="Google Play"
																	/>
																</a>
															</div>
															<div className="col-md-6 col-4">
																<a href="https://apps.apple.com/au/app/jainam-lite/id6471670339">
																	<img className="w-100"
																		src={toAbsoluteUrl("footer-images/appStore.svg")} alt="App Store" />
																</a>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-lg-9 col-12">
											<div className="row justify-content-between">
												{footerColumns.map((column, index) => (
													<FooterColumn
														key={index}
														title={column.title}
														links={column.links}
														subSections={column.subSections}
													/>
												))}
											</div>
										</div>
									</div>
									<div className="footer-bg">
										<div className="my-lg-4 mb-4">
											<div className="accordion" id="accordionExample">
												<div className="accordion-item bg-transparent border-0 ">
													<h2 className="accordion-header bg-transparent border-bottom">
														<button
															className="accordion-button accordion-ftr-button fs-18 in_med color28 bg-transparent px-0 collapsed"
															type="button"
															data-bs-toggle="collapse"
															data-bs-target="#collapseOne"
															aria-expanded="false"
															aria-controls="collapseOne">
															Important Information
														</button>
													</h2>
													<div
														id="collapseOne"
														className="accordion-collapse collapse bg-transparent"
														data-bs-parent="#accordionExample">
														<div className="accordion-body px-0 bg-transparent">
															<ul className="list-unstyled mt-3">
																{links.map((link, index) => (
																	<li
																		key={index}
																		className={`list-inline-item me-0 ${index !== 0 ? 'mt-lg-0 mt-2' : ''}`}>
																		<a
																			href={link.href}
																			target={target}
																			className="decoration fs-16 in_reg lh-19 color28 in_med footer-link">
																			{link.text}
																		</a>
																		{index < links.length - 1 && <span className="mx-2">|</span>}
																	</li>
																))}
															</ul>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div>
											<div className="slider-container">
												<Slider ref={(slider) => (slider = slider)} {...settings}>
													{sliderData.map((item, index) => (
														<div key={index}>
															<div className="in_bold fs-18 lh-24 color28">{item.title}</div>
															<div className="footer-desc my-3">{item.description}</div>
														</div>
													))}
												</Slider>
											</div>

											<div className="footer-container">
												{footerData.map((item, index) => (
													<div key={index} className="footer-desc my-3">
														{item.content}
													</div>
												))}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* </footer> */}
			</section>
		</>
	);
};

export default Footer;
