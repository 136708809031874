import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { API_ENDPOINTS, SITE_URL } from '../../assets/helpers/AssetHelpers';
import './style.css';
import useReRouteHook from '../../assets/hooks/useReRouteHook';
const SearchList = () => {
	const { searchTerm } = useParams();
	const [searchData, setSearchData] = useState([]);
	const [loading, setLoading] = useState(true);
	const { reRouteButtonRenderer } = useReRouteHook();

	useEffect(() => {
		setLoading(true);
		fetch(API_ENDPOINTS.getSearch, {
			method: 'POST',
			headers: new Headers({
				'Content-Type': 'application/x-www-form-urlencoded',
				}),
				body: `search_str=${encodeURIComponent(searchTerm || '')}`,
			})
			.then((result) => result.json())
			.then((data) => {
				if (data && data.data.length > 0) {
					setSearchData(data.data);
				} else {
					console.error('Invalid API response format:', data);
				}
			})
			.catch((error) => {
				console.error('Error fetching CMS content:', error);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [searchTerm]);
	return (
		<>
			<section>
				<div className="container-fluid">
					<div className="row ">
						<div className="col m-lg-5">
							<div className="row">
								<div className=" col-lg-10 mx-auto px-3 px-md-4 px-lg-3">
									{loading ? (
										<div className="loader3 mt-5">
											<div className="bars bar1"></div>
											<div className="bars bar2"></div>
											<div className="bars bar3"></div>
											<div className="bars bar4"></div>
											<div className="bars bar5"></div>
											<div className="bars bar6"></div>
											<div className="bars bar7"></div>
											<div className="bars bar8"></div>
											<div className="bars bar9"></div>
											<div className="bars bar10"></div>
										</div>
									) : (
										<>
											<nav aria-label="breadcrumb ">
												<ol className="breadcrumb mt-3 mt-lg-0 cursor-pointer global-font">
													<li className="breadcrumb-item active" aria-current="page">
														<a href="/" className="color1 in_reg lh-24">
															Home
														</a>
													</li>
													<li className="breadcrumb-item">
														<a href="/" className="color1 in_reg lh-24">
															{searchTerm}
														</a>
													</li>
												</ol>
											</nav>
											<h4 className="fs-24 py-3 in_semi search-head">
												Results for the keyword "{searchTerm}"
											</h4>
											<ul className="px-0">
												{searchData.map((item: any) => {
													return (
														<li
															className="mb-2 fs-16 search-list  list-unstyled in_med lh-24"
															style={{ padding: '10px 0' }}
															key={item.title}>
															{reRouteButtonRenderer({
																url: `/category/${encodeURIComponent(
																	item?.parent_map[1],
																)}/${encodeURIComponent(item?.parent_map[0])}/${encodeURIComponent(
																	item.cms_category,
																)}/${encodeURIComponent(item.id)}`,
																classes: 'color1',
																nameText: item.title.trim(),
															})}
														</li>
													);
												})}
											</ul>
										</>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default SearchList;
