import { ListItem } from "./ListItem";
import "./style.css"
interface ListSectionProps {
  title: string;
  items: { href: string; text: string }[];
}

export const ListSection: React.FC<ListSectionProps> = ({ title, items }) => (
  <>
    <div className="fs-18 li-title in_bold lh-22 color1 p-0 ">{title}</div>
    <ul className="list-unstyled my-4">
      {items.map((item: { href: any; text: any }, index: any) => (
        <ListItem key={index} href={item.href} text={item.text} />
      ))}
    </ul>
  </>
);
