import React from 'react';
import './videoModal.css';
interface VideoModalProps {
	videoSrc: string | null;
	isOpen: boolean;
	onClose: () => void;
	title: string;
	category: string;
}

const VideoModal: React.FC<VideoModalProps> = ({ videoSrc, isOpen, onClose, title, category }) => {
	if (!isOpen) return null;

	return (
		<div
			className="modal fade show modal-dialog-centered video-modal-dialog-centered"
			tabIndex={-1}
			style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}
			role="dialog">
			<div className="modal-dialog modal-lg" role="document">
				<div className="modal-content">
					<div className="modal-header border-bottom-0 pb-0">
						<div className="ur_bold fs-20 color30">{category}</div>
						<button
							type="button"
							className="btn-close video-modal-btn-close"
							onClick={onClose}
							aria-label="Close"></button>
					</div>
					<div className="modal-body">
						{/* <video src={videoSrc || ''} controls autoPlay className="w-100"></video> */}

						<iframe
							width="590"
							height="335"
							src={`${videoSrc || ''}?&rel=0&modestbranding=1&autoplay=1`}
							title="YouTube video player"
							frameBorder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							referrerPolicy="strict-origin-when-cross-origin"
							allowFullScreen
							className="rounded-3 my-3 video-section-modal"></iframe>
						<div className="in_reg fs-18 color30">{title}</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default VideoModal;
