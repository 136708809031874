import React from "react";
import Mainsection from "../mainsection/Mainsection";
import AboutSection from "../aboutsection/AboutSection";

const Home = () => {
  return (
    <>
      <Mainsection />
      {/* <AboutSection/> */}
    </>
  );
};

export default Home;
