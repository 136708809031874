import React, { useEffect, useState, useMemo } from "react";
import SubContent from "./SubContent";
import Sidebar from "./Sidebar"; // Assuming Sidebar exists
import { Link, useNavigate, useParams } from "react-router-dom";
import { API_ENDPOINTS, SITE_URL, toAbsoluteUrl } from "../../assets/helpers/AssetHelpers";
import "./style.css";
import MainSidebar from "../MainCategory/Sidebar";
import ParentCard from "../parentCard/parentCard";
import SubHeader from "./SubHeader";
import { Categories } from "../../types/types";
interface ApiResponse {
  data: {
    all_category: Categories[];
  };
}

const SubCategory = () => {
  const { category_name, child_category_name } = useParams();
  const formattedChildCategoryName = child_category_name;
  const navigate = useNavigate();

  const [data1, setData1] = useState<ApiResponse>({ data: { all_category: [] } });
  const [data2, setData2] = useState<any[]>([]);
  const [categoryData, setCategoryData] = useState<ApiResponse>({ data: { all_category: [] } });
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const result = await fetch(API_ENDPOINTS.getCMSCategory, {
          method: "POST",
          headers: new Headers({
            "Content-Type": "application/x-www-form-urlencoded",
          }),
          body: `site=${SITE_URL}`,
        });
        const categoriesResp = await result.json();
        setData1(categoriesResp);
      } catch (error) {
        console.error("Error fetching CMS categories:", error);
      } finally {
        setDataLoaded(true);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const result = await fetch(API_ENDPOINTS.getCMSContent, {
          method: "POST",
          headers: new Headers({
            "Content-Type": "application/x-www-form-urlencoded",
          }),
          body: `site=${SITE_URL}`,
        });
        const contentResp = await result.json();
        setData2(contentResp.data);
      } catch (error) {
        console.error("Error fetching CMS content:", error);
      } finally {
        setDataLoaded(true);
      }
    };

    fetchContent();
  }, []);

  // Memoize the filtered data for optimization
  const filteredData = useMemo(() => {
    return data1?.data?.all_category?.map((item: Categories) => {
      const subcategories = data2?.filter(
        (content: any) => content.cms_category === item.category_name
      );
      return { ...item, subcategories };
    });
  }, [data1, data2]);

  // Filtered data based on the parent category from params
  const mappedData = useMemo(() => {
    return filteredData?.filter(
      (filterData: any) => filterData?.parent_cms_category === formattedChildCategoryName
    );
  }, [filteredData, formattedChildCategoryName]);

  // Nested Categories Function (Recursive)
  const getSubcategories = (parentCategory: Categories, allCategories: Categories[]): Categories[] => {
    const subcategories = allCategories.filter(
      (category) => category.parent_cms_category === parentCategory.id
    );

    return subcategories.map((subcategory) => ({
      ...subcategory,
      subcategories: getSubcategories(subcategory, allCategories),
    }));
  };

  // Generate nested categories structure
  const nestedCategories = useMemo(() => {
    return data1?.data?.all_category
      .filter((category) => category.parent_cms_category === null || category.parent_cms_category === "")
      .map((category) => ({
        ...category,
        subcategories: getSubcategories(category, data1?.data?.all_category),
      }));
  }, [data1]);

  // Effect to fetch categories based on parent category (formattedChildCategoryName)
  useEffect(() => {
    const fetchCategoriesByParent = async (parentCategory: string) => {
      try {
        const result = await fetch(API_ENDPOINTS.getCMSCategory, {
          method: "POST",
          headers: new Headers({
            "Content-Type": "application/x-www-form-urlencoded",
          }),
          body: `site=${SITE_URL}&parent_category=${parentCategory}`,
        });

        if (!result.ok) {
          throw new Error('Network response was not ok');
        }

        const resp = await result.json();
        setCategoryData(resp); // This sets the category data from the API based on the parent category
      } catch (error) {
        console.error("Error fetching CMS categories based on parent category:", error);
      }
    };

    // Fetch categories only when `formattedChildCategoryName` is available
    if (formattedChildCategoryName) {
      fetchCategoriesByParent(formattedChildCategoryName);
    }
  }, [formattedChildCategoryName]);

  if (!dataLoaded) {
    // return <SkeletonLoader />; // Optional: show a loader while data is loading
  }

  // const filteredCategory = subcategoriesArray.filter(
  //   (item: any) => item?.title === child_category_name
  // );
  function toggleNavbar(event: { currentTarget: any }) {
    const button = event.currentTarget;
    const isExpanded = button.getAttribute("aria-expanded") === "false";

    if (isExpanded) {
      button.setAttribute("data-bs-state", "closed");
    } else {
      button.setAttribute("data-bs-state", "open");
    }
  }
  const className =
    process.env.REACT_APP_FULLWIDTH === "1"
      ? "col mx-lg-5 "
      : "col-lg-10 mx-auto px-4 px-lg-3";

  if (!category_name || !child_category_name) {
    navigate('/no-results-found');
    return null;
  }

  return (
    <>
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col m-lg-5">
              <div className="row">
                <div className={className}>
                  <SubHeader />
                  <ParentCard />
                  <div className="row">
                    <div className="col-lg-3 col-12 mt-lg-2 d-none d-lg-block">
                      <div className="accordion mt-4" id="sideBarAccordian">
                        {nestedCategories.map((category: Categories, index: number) => (
                          <Sidebar
                            key={category.id}
                            category_name={category.id}
                            category={category}
                            index={index}
                            url_category_name={category_name}
                            url_child_category_name={child_category_name}
                          />
                        ))}
                      </div>
                    </div>
                    <nav className="navbar navbar-expand-lg  d-block d-lg-none">
                      <div className="container-fluid justify-content-start px-3 px-md-4 px-lg-3">
                        <button
                          className="navbar-toggler border-0 p-0 pe-3"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#navbarNavAltMarkup"
                          aria-controls="navbarNavAltMarkup"
                          aria-expanded="false"
                          aria-label="Toggle navigation"
                          onClick={toggleNavbar}
                        >
                          <span className="navbar-toggler-icon"></span>
                        </button>
                        <a
                          className="navbar-brand in_reg fs-18 lh-24"
                          href="#"
                        >
                          View all categories
                        </a>
                        <div
                          className="collapse navbar-collapse"
                          id="navbarNavAltMarkup"
                        >
                          <div className="accordion my-4" id="sideBarAccordian">
                            {nestedCategories.map(
                              (category: Categories, index: number) => {
                                return (
                                  <>
                                    <MainSidebar
                                      category_name={category.id}
                                      category={category}
                                      index={index}
                                      url_category_name={category_name}
                                      url_child_category_name={
                                        child_category_name
                                      }
                                    />
                                  </>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </nav>
                    <div
                      className="col-lg-9 col-12 mt-lg-3 d-lg-block d-md-block d-none"
                    // style={{ width: "69%" }}
                    >
                      {dataLoaded ? (
                        <div
                          className="mx-2 color3 decoration p-3 p-md-2 pt-2"
                        >
                          <h5
                            className="py-1 color1 in_semi fs-28 px-2"
                          >
                            {child_category_name}
                          </h5>
                          {mappedData && mappedData.length > 0 ? (
                            <div className="accordion font-title mt-2" id="subContent">
                              {mappedData.map((item: any, index: number) => (
                                <SubContent
                                  key={item.id}
                                  filteredCategory={item}
                                  category_name={category_name}
                                  child_category_name={child_category_name}
                                  index={index}
                                />
                              ))}
                            </div>
                          ) : (
                            <div className="border-0 text-center">
                              <img
                                src={toAbsoluteUrl("about-section/no-faq.svg")}
                                alt="no_data_found"
                              />
                              <div className="row">
                                <p className="fs-6 py-2 bw_semi col-8 mt-4 mx-auto">
                                To find the information you need, please visit our home page at <Link to="/" className="color1">support.jainam.in</Link> and search for your query. 
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="loader3 py-4">
                          <div className="bars bar1"></div>
                          <div className="bars bar2"></div>
                          <div className="bars bar3"></div>
                          <div className="bars bar4"></div>
                          <div className="bars bar5"></div>
                          <div className="bars bar6"></div>
                          <div className="bars bar7"></div>
                          <div className="bars bar8"></div>
                          <div className="bars bar9"></div>
                          <div className="bars bar10"></div>
                        </div>
                      )}
                    </div>
                    <div className="col-lg-8 col-12 mt-lg-4 d-lg-none d-md-none d-block">
                      {dataLoaded ? (
                        <div className="mx-lg-2 color3 decoration p-lg-3">
                          <h5 className="py-2 color28 in_semi child-category-head fs-24 px-lg-2">
                            {formattedChildCategoryName}
                          </h5>
                          {mappedData && mappedData.length > 0 ? (
                            <div className="accordion font-title mt-2" id="subContent">
                              {mappedData.map((item: any, index: number) => (
                                <SubContent
                                  key={item.id}
                                  filteredCategory={item}
                                  category_name={category_name}
                                  child_category_name={child_category_name}
                                  index={index}
                                />
                              ))}
                            </div>
                          ) : (
                            <div className="border-0 text-center">
                              <img
                                src={toAbsoluteUrl("about-section/no-faq.svg")}
                                alt="no_data_found"
                              />
                                <p className="fs-6 py-2 bw_semi mt-2">
                                  To find the information you need, please visit our home page at <a href="support.jainam.in" className="color1">support.jainam.in</a> and search for your query. 
                              </p>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="loader3 py-4">
                          <div className="bars bar1"></div>
                          <div className="bars bar2"></div>
                          <div className="bars bar3"></div>
                          <div className="bars bar4"></div>
                          <div className="bars bar5"></div>
                          <div className="bars bar6"></div>
                          <div className="bars bar7"></div>
                          <div className="bars bar8"></div>
                          <div className="bars bar9"></div>
                          <div className="bars bar10"></div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <AboutSection /> */}
      </section>
    </>
  );
};

export default SubCategory;
