import React, { FC, ReactNode } from "react";
import { useLocation, Outlet, useParams } from "react-router-dom";
import Header from "../header/Header";
import HeroSection from "../herosection/HeroSection";
import Footer from "../footer/Footer";
import Navbar from "../common/Navbar/Navbar";
import AboutSection from "../aboutsection/AboutSection";
import DematSection from "../dematsection/DematSection";
import VideoSection from "../VideoSection/VideoSection";

type WithChildren = {
  children?: ReactNode;
};

const Content: FC<WithChildren> = ({ children }) => {
  return (
    <div id="kt_content_container" className="w-100">
      {children}
    </div>
  );
};

const MasterLayout = () => {
  const { searchTerm } = useParams();

  return (
    <div>
      {/* <Header /> */}
      <Navbar />
      <HeroSection />
      <Content>
        <Outlet />
      </Content>
      <VideoSection />
      {!searchTerm && <AboutSection />}
      <DematSection />
      <Footer />
    </div>
  );
};

export default MasterLayout;
