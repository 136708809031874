import React, { useEffect, useState } from "react";
import "./header.css";
import { toAbsoluteUrl } from "../../assets/helpers/AssetHelpers";

const Header = () => {
  const target = "_self";
  let toggleDropdownClass = "fade-in-div";
  const [isOpen, setIsOpen] = useState(false);
  const [opacityStep, setOpacityStep] = useState(0);

  const toggleDropdown = () => {
    if (isOpen) {
      closeDropdown();
    } else {
      setIsOpen(true);
      setOpacityStep(0.25);
      setTimeout(() => setOpacityStep(0.5), 250);
      setTimeout(() => setOpacityStep(0.75), 500);
      setTimeout(() => setOpacityStep(1), 750);
    }
  };

  const closeDropdown = () => {
    setOpacityStep(0.75);
    setTimeout(() => setOpacityStep(0.5), 250);
    setTimeout(() => setOpacityStep(0.25), 500);
    setTimeout(() => {
      setOpacityStep(0);
      setIsOpen(false);
    }, 750);
  };
  useEffect(() => {
    const link = document.createElement("link");
    link.rel = "preload";
    link.href =
      toAbsoluteUrl("header-images/header-logo.webp");
    link.as = "image";
    link.type = "image/webp";
    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, []);

  return (
    <>
      <section>
        <div className="container-fluid d-lg-block d-md-block d-none my-4">
          <div className="row">
            <div className="col-lg-12 d-flex align-items-center">
              <div className="col-lg-4">
                <a href="/">
                  <img
                    src={toAbsoluteUrl(
                      "/images/header-images/header-logo.webp"
                    )}
                    alt="Header Logo"
                    width="1200" // Replace with your image's actual width
                    height="800" // Replace with your image's actual height
                    className="mb-1"
                    style={{ width: "100%", height: "auto" }}
                  />
                </a>
              </div>
              <div className="col-lg-5">
                <ul className="text-center navbar-nav pt-4 mb-0 flex-row">
                  <li
                    style={{ padding: "0 40px 0 5px" }}
                    className=" text-size"
                  >
                    <a href="https://jainam.in/Home/Index">
                      <img
                        src={toAbsoluteUrl("/images/header-images/home.svg")}
                        alt="home-icon"
                        style={{ width: "40px", height: "40px" }}
                        className="home-hover"
                      />
                    </a>
                    <p className="text-size bw_reg">Home</p>
                  </li>
                  <li
                    style={{ padding: "0 40px 0 0" }}
                    className=" text-size d-none"
                  >
                    <a href="https://jainam.in/Home/AboutUs">
                      <img
                        src={toAbsoluteUrl("/images/header-images/aboutus.svg")}
                        alt="aboutus-icon"
                        style={{ width: "40px", height: "40px" }}
                        className="about-hover"
                      />
                    </a>
                    <p className="text-size bw_reg">About Us</p>
                  </li>
                  <li style={{ padding: "0 40px 0 0" }} className=" text-size">
                    <a href="https://jainam.in/Home/Broking">
                      <img
                        src={toAbsoluteUrl(
                          "/images/header-images/broking-icon.svg"
                        )}
                        alt="broking-icon"
                        style={{ width: "40px", height: "40px" }}
                        className="broking-hover"
                      />
                    </a>
                    <p className="text-size bw_reg">Broking</p>
                  </li>
                  <li style={{ padding: "0 40px 0 0" }} className=" text-size">
                    <a href="https://jainam.in/Home/OptionTrading">
                      <img
                        src={toAbsoluteUrl(
                          "/images/header-images/deltadesk-icon.svg"
                        )}
                        alt="deltadesk-icon"
                        style={{ width: "40px", height: "40px" }}
                        className="delta-hover"
                      />
                    </a>
                    <p className="text-size bw_reg">Option Trading</p>
                  </li>
                  <li style={{ padding: "0 40px 0 0" }} className=" text-size">
                    <a href="https://jainam.in/Home/Services">
                      <img
                        src={toAbsoluteUrl(
                          "/images/header-images/services-icon.svg"
                        )}
                        alt="services-icon"
                        style={{ width: "40px", height: "40px" }}
                        className="services-hover"
                      />
                    </a>
                    <p className="text-size bw_reg">Services</p>
                  </li>
                  <li style={{ padding: "0 40px 0 0" }} className=" text-size">
                    <a href="https://events.jainam.in/#/home">
                      <img
                        src={toAbsoluteUrl(
                          "/images/header-images/events-icon.svg"
                        )}
                        alt="events-icon"
                        style={{ width: "40px", height: "40px" }}
                        className="events-hover"
                      />
                    </a>
                    <p className="text-size bw_reg">Events</p>
                  </li>
                  <li style={{ padding: "0 40px 0 0" }} className=" text-size">
                    <a href="https://jainam.in/Home/ContactUs">
                      <img
                        src={toAbsoluteUrl(
                          "/images/header-images/contact-icon.svg"
                        )}
                        alt="contact-icon"
                        style={{ width: "40px", height: "40px" }}
                        className="contact-hover"
                      />
                    </a>
                    <p className="text-size bw_reg">Contact Us</p>
                  </li>
                  <li style={{ padding: "0 40px 0 0" }} className=" text-size">
                    <a href="https://support.jainam.in">
                      <img
                        src={toAbsoluteUrl("/images/header-images/support.svg")}
                        alt="support-icon"
                        style={{ width: "40px", height: "40px" }}
                        className="support-hover"
                      />
                    </a>
                    <p className="text-size bw_reg">Support</p>
                  </li>
                </ul>
              </div>

              <div className="position-relative">
                <button
                  className="dropdown-btn bg-color12 border-0"
                  style={{
                    marginLeft: "65px",
                    borderRadius: "40px",
                    padding: "10px",
                    height: "40px",
                    width: "40px",
                  }}
                  onClick={toggleDropdown}
                >
                  <img
                    src={toAbsoluteUrl("/images/header-images/grid-icon.png")}
                    alt="grid-icon"
                    className="img-fluid"
                    style={{ width: "20px", height: "20px" }}
                  />
                </button>
                <div
                  className={`position-absolute dropdown ${isOpen ? "open" : "d-none"
                    }`}
                  id="myDropdown"
                >
                  <div
                    className={`w-100 dropdown-content ${isOpen ? "open" : "d-none"
                      }`}
                    style={{ opacity: opacityStep }}
                  >
                    <div
                      className="d-flex justify-content-evenly"
                      style={{ borderRadius: "10px" }}
                    >
                      <div className="col-lg-12 d-flex justify-content-evenly">
                        <ul
                          className="d-flex dropdown-menu mt-1 text-center pt-4 kk shadow col-2"
                          style={{ width: "470px", right: "-100px" }}
                        >
                          <div className="me-2 ms-4">
                            <li className="hovereffect">
                              <a
                                className="dropdown-item"
                                href="https://jainam.in/Home/Download"
                              >
                                <img
                                  src={toAbsoluteUrl(
                                    "/images/header-images/download-dw.png"
                                  )}
                                  alt="download-dw"
                                  style={{ width: "50px" }}
                                />
                                <p className="color27 fs-6 py-2 bw_reg">
                                  Download
                                </p>
                              </a>
                            </li>
                            <li className="hovereffect">
                              <a
                                className="dropdown-item"
                                href="https://space.jainam.in/#/?returnUrl=%2Fdashboard"
                              >
                                <img
                                  src={toAbsoluteUrl(
                                    "/images/header-images/space-dw.png"
                                  )}
                                  alt="space-dw"
                                  style={{ width: "50px" }}
                                />
                                <p className="color27 fs-6 py-2 bw_reg">
                                  Space
                                </p>
                              </a>
                            </li>
                          </div>
                          <div className="mx-2 ms-3">
                            <li className="hovereffect">
                              <a
                                className="dropdown-item"
                                href="https://wealthelite.in/Login/login/client-login"
                              >
                                <img
                                  src={toAbsoluteUrl(
                                    "/images/header-images/mutualfund-dw.png"
                                  )}
                                  alt="mutualfund-dw"
                                  style={{ width: "50px" }}
                                />
                                <p className="color27 fs-6 py-2 bw_reg">
                                  Mutual Fund
                                </p>
                              </a>
                            </li>
                            <li className="hovereffect">
                              <a
                                className="dropdown-item"
                                href="https://cash.jainam.in/Payment/MakePayment"
                              >
                                <img
                                  src={toAbsoluteUrl(
                                    "/images/header-images/funds-dw.png"
                                  )}
                                  alt="funds-dw"
                                  style={{ width: "50px" }}
                                />
                                <p className="color27 fs-6 py-2 bw_reg">
                                  Funds
                                </p>
                              </a>
                            </li>
                          </div>
                          <div className="mx-2 ms-3">
                            <li className="hovereffect">
                              <a
                                className="dropdown-item"
                                href="http://online.jainam.in/Aero/login"
                              >
                                <img
                                  src={toAbsoluteUrl(
                                    "/images/header-images/trading-dw.png"
                                  )}
                                  alt="trading-dw"
                                  style={{ width: "50px" }}
                                />
                                <p className="color27 fs-6 py-2 bw_reg">
                                  Trading
                                </p>
                              </a>
                            </li>
                            <li className="hovereffect">
                              <a
                                className="dropdown-item"
                                href="https://backoffice.jainam.in/"
                              >
                                <img
                                  src={toAbsoluteUrl(
                                    "/images/header-images/backoffice-dw.png"
                                  )}
                                  alt="backoffice-dw"
                                  style={{ width: "50px" }}
                                />
                                <p className="color27 fs-6 py-2 bw_reg">
                                  Tech Excel
                                </p>
                              </a>
                            </li>
                          </div>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ============================================Responsive======================================================= */}
      <section>
        <div className="container d-lg-none d-md-none d-block">
          <div className="col-12">
            <div className="bottom-style">
              <ul className="text-center navbar-nav mb-0 flex-row justify-content-between">
                <li className="" style={{ padding: "0 5px 0 5px" }}>
                  <a href="https://jainam.in/Home/Index">
                    <img
                      src={toAbsoluteUrl("/images/header-images/home.svg")}
                      alt="home-icon"
                      style={{ width: "20px", height: "30px" }}
                      className="home-hover"
                    />
                  </a>
                  <p className="bw_reg" style={{ fontSize: "9px" }}>
                    Home
                  </p>
                </li>
                <li className="" style={{ padding: "0 5px 0 5px" }}>
                  <a href="https://jainam.in/Home/Broking">
                    <img
                      src={toAbsoluteUrl(
                        "/images/header-images/broking-icon.svg"
                      )}
                      alt="broking-icon"
                      style={{ width: "20px", height: "30px" }}
                      className="broking-hover"
                    />
                  </a>
                  <p className="bw_reg" style={{ fontSize: "9px" }}>
                    Broking
                  </p>
                </li>
                <li className="" style={{ padding: "0 10px 0 5px" }}>
                  <a href="https://jainam.in/Home/OptionTrading">
                    <img
                      src={toAbsoluteUrl(
                        "/images/header-images/deltadesk-icon.svg"
                      )}
                      alt="deltadesk-icon"
                      style={{ width: "20px", height: "30px" }}
                      className="delta-hover"
                    />
                  </a>
                  <p className="bw_reg" style={{ fontSize: "9px" }}>
                    Option Trading
                  </p>
                </li>
                <li className="" style={{ padding: "0 10px 0 5px" }}>
                  <a href="https://jainam.in/Home/Services">
                    <img
                      src={toAbsoluteUrl(
                        "/images/header-images/services-icon.svg"
                      )}
                      alt="services-icon"
                      style={{ width: "20px", height: "30px" }}
                      className="services-hover"
                    />
                  </a>
                  <p className="bw_reg" style={{ fontSize: "9px" }}>
                    Services
                  </p>
                </li>
                <li className="" style={{ padding: "0 10px 0 5px" }}>
                  <a href="https://events.jainam.in/#/home">
                    <img
                      src={toAbsoluteUrl(
                        "/images/header-images/events-icon.svg"
                      )}
                      alt="events-icon"
                      style={{ width: "20px", height: "30px" }}
                      className="events-hover"
                    />
                  </a>
                  <p className="bw_reg" style={{ fontSize: "9px" }}>
                    Events
                  </p>
                </li>
                <li className="" style={{ padding: "0 10px 0 5px" }}>
                  <a href="https://jainam.in/Home/ContactUs">
                    <img
                      src={toAbsoluteUrl(
                        "/images/header-images/contact-icon.svg"
                      )}
                      alt="contact-icon"
                      style={{ width: "20px", height: "30px" }}
                      className="contact-hover"
                    />
                  </a>
                  <p className="bw_reg" style={{ fontSize: "9px" }}>
                    Contact Us
                  </p>
                </li>
                <li className="" style={{ padding: "0 10px 0 5px" }}>
                  <a href="https://support.jainam.in">
                    <img
                      src={toAbsoluteUrl("/images/header-images/support.svg")}
                      alt="contact-icon"
                      style={{ width: "20px", height: "30px" }}
                      className="support-hover"
                    />
                  </a>
                  <p className="bw_reg" style={{ fontSize: "9px" }}>
                    Support
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <nav className="navbar navbar-expand-lg bg-light">
            <div className="container">
              <a href="/">
                <img
                  src={toAbsoluteUrl("/images/header-images/res-logo.png")}
                  alt="Header_Duck"
                  style={{ width: "200px", marginLeft: "-30px" }}
                />
              </a>
              <div className="position-relative">
                <button
                  className="dropdown-btn bg-color12 border-0 ms-5"
                  style={{
                    borderRadius: "40px",
                    padding: "10px",
                    height: "40px",
                    width: "40px",
                  }}
                  onClick={toggleDropdown}
                >
                  <img
                    src={toAbsoluteUrl("/images/header-images/grid-icon.png")}
                    alt="grid-icon"
                    className="img-fluid"
                    style={{ width: "20px", height: "20px" }}
                  />
                </button>
                <div
                  className={`position-absolute dropdown ${isOpen ? "open " : "d-none"
                    } `}
                  id="myDropdown"
                >
                  <div
                    className={`w-100 dropdown-content ${isOpen ? "open" : "d-none"
                      }`}
                    style={{ opacity: opacityStep }}
                  >
                    <div
                      className="d-flex justify-content-evenly"
                      style={{ borderRadius: "10px" }}
                    >
                      <div className="col-lg-12 d-flex justify-content-evenly">
                        <ul
                          className="d-flex dropdown-menu mt-1 text-center pt-4 kk shadow col-2"
                          style={{ width: "340px", right: "-77px" }}
                        >
                          <div className="">
                            <li className="hovereffect">
                              <a
                                className="dropdown-item"
                                href="https://jainam.in/Home/Download"
                              >
                                <img
                                  src={toAbsoluteUrl(
                                    "/images/header-images/download-dw.png"
                                  )}
                                  alt="download-dw"
                                  style={{ width: "35px" }}
                                />
                                <p
                                  className="color27  py-1 bw_reg"
                                  style={{ fontSize: "14px" }}
                                >
                                  Download
                                </p>
                              </a>
                            </li>
                            <li className="hovereffect">
                              <a
                                className="dropdown-item"
                                href="https://space.jainam.in/#/?returnUrl=%2Fdashboard"
                              >
                                <img
                                  src={toAbsoluteUrl(
                                    "/images/header-images/space-dw.png"
                                  )}
                                  alt="space-dw"
                                  style={{ width: "35px" }}
                                />
                                <p
                                  className="color27  py-1 bw_reg"
                                  style={{ fontSize: "14px" }}
                                >
                                  Space
                                </p>
                              </a>
                            </li>
                          </div>
                          <div className="">
                            <li className="hovereffect">
                              <a
                                className="dropdown-item"
                                href="https://wealthelite.in/Login/login/client-login"
                              >
                                <img
                                  src={toAbsoluteUrl(
                                    "/images/header-images/mutualfund-dw.png"
                                  )}
                                  alt="mutualfund-dw"
                                  style={{ width: "35px" }}
                                />
                                <p
                                  className="color27  py-1 bw_reg"
                                  style={{ fontSize: "14px" }}
                                >
                                  Mutual Fund
                                </p>
                              </a>
                            </li>
                            <li className="hovereffect">
                              <a
                                className="dropdown-item"
                                href="https://cash.jainam.in/Payment/MakePayment"
                              >
                                <img
                                  src={toAbsoluteUrl(
                                    "/images/header-images/funds-dw.png"
                                  )}
                                  alt="funds-dw"
                                  style={{ width: "35px" }}
                                />
                                <p
                                  className="color27  py-1 bw_reg"
                                  style={{ fontSize: "14px" }}
                                >
                                  Funds
                                </p>
                              </a>
                            </li>
                          </div>
                          <div className="">
                            <li className="hovereffect">
                              <a
                                className="dropdown-item"
                                href="http://online.jainam.in/Aero/login"
                              >
                                <img
                                  src={toAbsoluteUrl(
                                    "/images/header-images/trading-dw.png"
                                  )}
                                  alt="trading-dw"
                                  style={{ width: "35px" }}
                                />
                                <p
                                  className="color27  py-1 bw_reg"
                                  style={{ fontSize: "14px" }}
                                >
                                  Trading
                                </p>
                              </a>
                            </li>
                            <li className="hovereffect">
                              <a
                                className="dropdown-item"
                                href="https://backoffice.jainam.in/"
                              >
                                <img
                                  src={toAbsoluteUrl(
                                    "/images/header-images/backoffice-dw.png"
                                  )}
                                  alt="backoffice-dw"
                                  style={{ width: "35px" }}
                                />
                                <p
                                  className="color27  py-1 bw_reg"
                                  style={{ fontSize: "14px" }}
                                >
                                  Tech Excel
                                </p>
                              </a>
                            </li>
                          </div>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </section>
    </>
  );
};

export default Header;
