import React from 'react';
import './sidebar.css';
import '../../assets/sass/global.css';
import { toAbsoluteUrl } from '../../assets/helpers/AssetHelpers';
import { useNavigate } from 'react-router-dom';
import { Categories } from '../../types/types';
import useReRouteHook from '../../assets/hooks/useReRouteHook';

interface Props {
	category: Categories;
	category_name: string;
	index: number;
	url_category_name: string;
	url_child_category_name?: string;
}
const MainSidebar = ({
	category_name,
	category,
	index,
	url_category_name,
	url_child_category_name,
}: Props) => {
	const navigate = useNavigate();
	const isCategoryActive = category_name === url_category_name;
	// const handleSubcategoryNavigate = (category: string, subcategory: string) => {
	//   navigate(`/category/${category}/${subcategory}/`);
	// };
	const { reRouteButtonRenderer } = useReRouteHook();
	return (
		<>
			{isCategoryActive && (
				<div
					className={`accordion-item sidebar-accordion-item bw_reg border-0 mx-lg-1 ${isCategoryActive ? 'active' : ''
						}`}>
					<h5
						className="accordion-header sidebar-accordion-header global-font sub-c-style"
					>

						<button
							className={`text-start ms-2 py-2 color1 fs-20 in_semi ${isCategoryActive ? '' : 'collapsed'} px-0`}
							style={{
								backgroundColor: 'white',
								border: 'none',
								boxShadow: 'none',

								color: '#2b948f',
							}}
							type="button"
							data-bs-target={`#sideBarCollapse${index}`}
							aria-expanded={isCategoryActive ? 'true' : 'false'}
							aria-controls={`#sideBarCollapse${index}`}>
							{isCategoryActive ? category_name : ''}
						</button>
					</h5>
					{isCategoryActive && category && (
						<div
							id={`sideBarCollapse${index}`}
							className={`accordion-collapse mt-lg-3 ms-lg-1 sidebar-accordion-collapse ${isCategoryActive ? 'show' : 'collapse'
								}`}
							data-bs-parent="#sideBarAccordian">
							<div className="">
								<ul className="list-unstyled">
									{category?.subcategories &&
										category?.subcategories?.map((subcategory: Categories) => {
											const isThisSubcategoryActive =
												subcategory?.category_name === url_child_category_name;
											return (
												<div>
													{reRouteButtonRenderer({
														url: `/category/${category_name}/${subcategory?.category_name}`,
														classes: `py-1 global-font my-1 b1 in_reg text-start ${isThisSubcategoryActive ? 'active' : ''
															}`,
														nameText: subcategory?.category_name,
														spanClasses: 'link-hover',
													})}
												</div>
											);
										})}
								</ul>
							</div>
						</div>
					)}
				</div>
			)}
		</>
	);
};

export default MainSidebar;
