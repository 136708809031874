import React, { useEffect, useState } from "react";
import MainSectionCard from "./MainsectionCard";
import {
  API_ENDPOINTS,
  SITE_URL,
  toAbsoluteUrl,
} from "../../assets/helpers/AssetHelpers";
import { Categories } from "../../types/types";
interface Category {
  id: string;
  category_name: string;
  parent_cms_category: string;
}
interface ApiResponse {
  data: {
    all_category: Category[];
  };
}

const MainSection = () => {
  const [data1, setData1] = useState<ApiResponse>({
    data: {
      all_category: [],
    },
  });


  useEffect(() => {
    fetch(API_ENDPOINTS.getCMSCategory, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
      body: `site=${SITE_URL}`,
    }).then((result) => {
      result.json().then((resp) => {
        setData1(resp);
      });
    });
  }, []);

  const getSubcategories = (parentCategory: any, allCategories: any) => {
    const subcategories = allCategories.filter(
      (category: any) => category.parent_cms_category === parentCategory.id
    );

    if (subcategories.length === 0) {
      return null;
    }

    return subcategories.map((subcategory: any) => ({
      ...subcategory,
      subcategories: getSubcategories(subcategory, allCategories),
    }));
  };



  const nestedCategories: Omit<Categories, 'is_group' | 'level' | 'sorting_order'>[] = data1?.data?.all_category
    ?.filter(
      (category) =>
        category.parent_cms_category === null ||
        category.parent_cms_category === ""
    )
    .map((category) => ({
      ...category,
      subcategories: getSubcategories(category, data1?.data?.all_category),
    }));
  
  const className =
    process.env.REACT_APP_FULLWIDTH === "1"
      ? "col mx-lg-5 mb-lg-5"
      : "col-lg-10 mx-auto";
  
  return (
    <>
      <section>
        <div className="container-fluid mb-5 mb-lg-0">
          <div className="row">
            <div className="col mx-lg-5 mb-lg-5">
              <div className="row">
                <div className={className}>
                  <div className="row mx-0">
                    <div className="col-lg-12 col-12 px-0 px-md-3 px-lg-0  ">
                      <div className="pt-3 pb-1 mt-lg-3 fs-28 ur_ebold color28 d-lg-block d-none">
                        To help you, select a relevant topic
                      </div>
                    </div>
                  </div>
                  <div
                    className="row p-0 px-md-3 px-lg-0 gy-4 justify-content-start mt-0"
                    data-masonry='{"percentPosition": true }'
                  >
                    {nestedCategories.map((category) => (
                      <>
                        <MainSectionCard key={category.id} cards={category} />
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default React.memo(MainSection);
