import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Header from "../../components/header/Header";
import MasterLayout from "../../components/masterlayout/MasterLayout";
import Home from "../../components/homepage/Home";
import SubCategory from "../../components/subcategory/SubCategory";
import MainSection from "../../components/mainsection/Mainsection";
import Footer from "../../components/footer/Footer";
import HeroSection from "../../components/herosection/HeroSection";
import ChildCategory from "../../components/childcategory/ChildCategory";
import Notfound from "../../components/notfound/Notfound";
import SearchList from "../../components/searchlist/SearchList";
import MainCategory from "../../components/MainCategory/MainCategory";
import SubChildCategory from "../../components/subchildcategory/SubChildCategory";
import NoResultsSearch from "../../components/NoResultsSearch/NoResultsSearch";
import VideoPage from "../../components/VideoPage/VideoPage";
import { API_ENDPOINTS } from "../../assets/helpers/AssetHelpers";

const PrivateRoute = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/login" || location.pathname === "/signup") {
      window.location.replace(API_ENDPOINTS.loginURL);
    }
  }, [location]);
  return (
    <div>
      <Routes>
        <Route element={<MasterLayout />}>
          <Route path="/" element={<Home />} />
          <Route
            path="/category/:category_name/:child_category_name"
            element={<SubCategory />}
          />
          <Route path="/category/:category_name/" element={<MainCategory />} />
          <Route
            path="/category/:category_name/:child_category_name/:child/"
            element={<SubChildCategory />}
          />
          <Route
            path="/category/:category_name/:child_category_name/:child/:childsub"
            element={<ChildCategory />}
          />
          <Route path="/header" element={<Header />} />
          <Route path="/content" element={<MainSection />} />
          <Route path="/footer" element={<Footer />} />
          <Route path="/herosection" element={<HeroSection />} />
          <Route path="/search-results/:searchTerm" element={<SearchList />} />
          <Route
            path="/no-results-found/:searchTerm"
            element={<NoResultsSearch />}
          />
          <Route path="*" element={<Notfound />} />
        </Route>
        <Route path="/videos" element={<VideoPage />}>
        </Route>
      </Routes>
    </div >
  );
};

export default PrivateRoute;
