import React, { useEffect, useState } from 'react';
import './herosection.css';
import { API_ENDPOINTS, SITE_URL, toAbsoluteUrl } from '../../assets/helpers/AssetHelpers';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import useReRouteHook from '../../assets/hooks/useReRouteHook';

const HeroSection = () => {
	const [data2, setData2] = useState([]);
	const [searchTerm, setSearchTerm] = useState('');
	const [suggestions, setSuggestions] = useState([]);
	const [showNoResultsMessage, setShowNoResultsMessage] = useState(false);
	const [showDropdown, setShowDropdown] = useState(false);
	const [loading, setLoading] = useState(false);
	const [hasResults, setHasResults] = useState(true);
	const navigate = useNavigate();
	const location = useLocation();
	const { reRouteButtonRenderer } = useReRouteHook();

	const fetchSuggestions = async (searchTerm: string) => {
		try {
			const response = await fetch(API_ENDPOINTS.getSearch, {
				method: 'POST',
				headers: new Headers({
					'Content-Type': 'application/x-www-form-urlencoded',
				}),
				body: `search_str=${encodeURIComponent(searchTerm)}`,
			});

			const result = await response.json();
			return result.data || [];
		} catch (error) {
			console.error('Error fetching search results:', error);
			return [];
		}
	};

	useEffect(() => {
		if (searchTerm) {
			setLoading(true);
			const timeoutId = setTimeout(async () => {
				const fetchedResults = await fetchSuggestions(searchTerm);

				setSuggestions(fetchedResults);
				setLoading(false);

				setHasResults(fetchedResults.length > 0);
			}, 500);

			return () => clearTimeout(timeoutId);
		} else {
			setSuggestions([]);
			setLoading(false);
		}
	}, [searchTerm]);

	const handleKeyPress = (e: any) => {
		if (loading) {
			return;
		}

		if (e.key === 'Enter') {
			if (suggestions.length > 0 && hasResults) {
				navigate(`/search-results/${searchTerm}`);
			} else {
				navigate(`/no-results-found/${searchTerm}`);
			}
			setSuggestions([]);
			setSearchTerm('');
		}
	};
	const handleSearchTerm = (term: any) => {
		setSearchTerm(term);
		setShowDropdown(!!term);
	};

	const updatedSuggestions = suggestions.map((item: any) => {
		const uniqueParentMapSet = new Set(
			item?.parent_map.map((suggestionItem: any) => suggestionItem),
		);
		return {
			...item,
			parent_map: Array.from(uniqueParentMapSet),
		};
	});
	// const updatedSuggestions = suggestions.map((item: any) => {
	// 	const uniqueParentMapSet = new Set(
	// 		(item?.parent_map || []).map((suggestionItem: any) => suggestionItem),
	// 	);
	// 	return {
	// 		...item,
	// 		parent_map: Array.from(uniqueParentMapSet),
	// 	};
	// });

	return (
		<>
			<section className="d-lg-block d-md-block d-none" style={{ backgroundColor: '#E6F6DE' }}>
				<div className="container-fluid">
					<div className="row">
						<div className="col mx-5 my-4 text-center">
							<div className="row">
								<div className="col-lg-10 mx-auto my-5">
									{/* <p className="title ur_ebold color28 fs-44 d-md-none d-lg-block">
								How can we help you?
							</p> */}
									{location.pathname === '/' && (
										<p className="subtitle ur_reg fs-18 in_reg color28 d-md-none">
											Here are a few of the questions we get the most. If you don't see what's on
											your mind, reach out to us anytime on phone, chat, or email.
										</p>
									)}
									<div className="dropdown" style={{ position: 'relative' }}>
										<div className="btn-group w-100">
											<div
												style={{
													position: 'absolute',
													left: '15px',
													top: '50%',
													transform: 'translateY(-50%)',
													zIndex: 2,
												}}>
												<img
													src={toAbsoluteUrl('herosection-images/searchicon.svg')}
													className="w-100 h-100"
													alt="Search Icon"
												/>
											</div>
											<input
												className="btn btn-lg in_med dropdown-toggle border-secondary fs-6 bg-light text-start ps-5 py-3 px-2"
												style={{ fontSize: '16px', borderRadius: '8px' }}
												type="text"
												placeholder="Type your query here for FAQ's"
												value={searchTerm}
												onChange={(e) => handleSearchTerm(e.target.value)}
												onKeyPress={(e) => handleKeyPress(e)}
												data-bs-toggle="dropdown"
												aria-expanded="false"
												role="button"
												aria-label="Dropdown Toggle"
											/>
											{searchTerm && (
												<button
													onClick={(e) => handleSearchTerm('')}
													style={{
														position: 'absolute',
														right: '10px',
														top: '56%',
														transform: 'translateY(-50%)',
														background: 'none',
														border: 'none',
														cursor: 'pointer',
														zIndex: 1,
													}}>
													<FontAwesomeIcon
														className="p-1"
														icon={faCircleXmark}
														style={{ color: 'gray' }}
													/>
												</button>
											)}
											<ul
												style={{ maxHeight: '500px' }}
												className={`dropdown-menu mt-1 custom-scroll ${
													searchTerm ? 'show w-100 z-3' : ''
												}`}>
												{loading ? (
													<li>
														<div className="search-result-message px-4 py-1 bw_med text-center">
															Searching Results
														</div>
													</li>
												) : searchTerm && updatedSuggestions.length > 0 ? (
													updatedSuggestions.map((item: any) => {
														// const highlightedTitle = item.title
														// 	.split(new RegExp(`(${searchTerm})`, 'gi'))
														// 	.map((part: any, index: number) =>
														// 		part.toLowerCase() === searchTerm.toLowerCase() ? (
														// 			<strong key={index}>{part}</strong>
														// 		) : (
														// 			part
														// 		),
														// );
														
														// const highlightedCategory = item.cms_category
														// .split(new RegExp(`(${searchTerm})`, 'gi'))
														// .map((part: any, index: number) =>
														// 	part.toLowerCase() === searchTerm.toLowerCase() ? (
														// 		<span key={index} className='highlightedCategoryColor'>{part}</span>
														// 	) : (
														// 		part
														// 	),
														// );

														const searchWords = searchTerm.toLowerCase().split(/\s+/).filter(Boolean); // Convert to lowercase

														const highlightedTitle = item.title
															.split(new RegExp(`(${searchWords.join('|')})`, 'gi'))
															.map((part: any, index: number) =>
																searchWords.includes(part.toLowerCase()) ? <strong key={index}>{part}</strong> : part
															);

														const highlightedCategory = item.cms_category
															.split(new RegExp(`(${searchWords.join('|')})`, 'gi'))
															.map((part: any, index: number) =>
																searchWords.includes(part.toLowerCase()) ? (
																	<span key={index} className="highlightedCategoryColor">{part}</span>
																) : (
																	part
																)
															);


														return (
															<li key={item.title} className="py-3 px-4 in_reg border-bottom ms-2">
																<div className="fs-12 mb-2 color34">{highlightedCategory}</div>
																<div>
																	{reRouteButtonRenderer({
																		url: `/category/${encodeURIComponent(
																			item?.parent_map[1],
																		)}/${encodeURIComponent(
																			item?.parent_map[0],
																		)}/${encodeURIComponent(
																			item.cms_category,
																		)}/${encodeURIComponent(item.id)}`,
																		classes: 'suggestion-item fs-6 p-0',
																		nameText: highlightedTitle,
																	})}
																</div>
															</li>
														);
													})
												) : (
													<li>
														<div
															className="no-results-message px-4 py-1 in_reg"
															style={{ borderRadius: '10px' }}>
															{searchTerm === '' ? 'Write anything to search' : 'No Result Found'}
														</div>
													</li>
												)}
											</ul>
										</div>
									</div>
									<div className="pt-4 text-decortion-none d-none">
										<ul className="d-flex justify-content-evenly list-unstyled">
											<li>
												<a href="" className="link-color1 text-decoration-underline">
													Bulletin
												</a>
												<sup className="px-1 text-decoration-none" style={{ color: '#2B948F' }}>
													New
												</sup>
											</li>
											<li>
												<a href="" className="link-color1 text-decoration-underline">
													Circulars
												</a>
											</li>
											<li>
												<a href="" className="link-color1 text-decoration-underline">
													Announcement
												</a>
											</li>
										</ul>
									</div>
									{/* <div className="fs-20 my-4 text-start ur_semi color28">Most Searched FAQs</div>
									<div className="row">
										<div className="col-12 col-md-6 col-lg-4 ">
											<ul>
												<li className="text-start">
													{reRouteButtonRenderer({
														url: `/category/1/1/Account/1`,
														classes: 'color37 fs-18 text-decoration-none in_med lh-21',
														nameText:
															'How to activate and deactivate Securities Lending and Borrowing (SLB...',
													})}
													{reRouteButtonRenderer({
														url: `/category/`,
														classes: 'color38 text-decoration-none in_reg my-1',
														nameText: 'Addition & Withdrawal of Funds',
													})}
												</li>
											</ul>
										</div>
										<div className="col-12 col-md-6 col-lg-4 ">
											<ul>
												<li className="text-start">
													{reRouteButtonRenderer({
														url: `/category/1/1/Account/1`,
														classes: 'color37 fs-18 text-decoration-none in_med lh-21',
														nameText:
															'How to activate and deactivate Securities Lending and Borrowing (SLB...',
													})}
													{reRouteButtonRenderer({
														url: `/category/`,
														classes: 'color38 text-decoration-none in_reg my-1',
														nameText: 'Addition & Withdrawal of Funds',
													})}
												</li>
											</ul>
										</div>
										<div className="col-12 col-md-6 col-lg-4 ">
											<ul>
												<li className="text-start">
													{reRouteButtonRenderer({
														url: `/category/1/1/Account/1`,
														classes: 'color37 fs-18 text-decoration-none in_med lh-21',
														nameText:
															'How to activate and deactivate Securities Lending and Borrowing (SLB...',
													})}
													{reRouteButtonRenderer({
														url: `/category/`,
														classes: 'color38 text-decoration-none in_reg my-1',
														nameText: 'Addition & Withdrawal of Funds',
													})}
												</li>
											</ul>
										</div>
									</div> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* ===========================================Responsive code ======================================================== */}

			<section style={{ backgroundColor: '#E6F6DE' }}>
				<div className="container py-4 d-lg-none d-md-none d-block">
					<div className="row">
						<div className="col-lg-6 mx-auto text-center px-4">
							{/* <p className="title ur_bold fs-24 color28 d-none">How can we help you?</p> */}
							{location.pathname === '/' && (
								<p className="subtitle ur_reg fs-18 in_reg color28 d-none">
									Here are a few of the questions we get the most. If you don't see what's on your
									mind, reach out to us anytime on phone, chat, or email.
								</p>
							)}
							<div className="dropdown w-100" style={{ position: 'relative' }}>
								<div
									className="btn-group text-center w-100"
									style={{ width: '350px', height: '48px' }}>
									<div
										style={{
											position: 'absolute',
											left: '15px',
											top: '50%',
											transform: 'translateY(-50%)',
											zIndex: 2,
										}}>
										<img
											src={toAbsoluteUrl('herosection-images/searchicon.svg')}
											className="w-100 h-100"
											alt="Search Icon"
										/>
									</div>
									<input
										className="btn btn-lg in_med dropdown-toggle border-secondary fs-6 bg-light text-start ps-5 py-3 px-2"
										style={{ fontSize: '16px', borderRadius: '8px' }}
										type="text"
										placeholder="Type your query here for FAQ's"
										value={searchTerm}
										onChange={(e) => handleSearchTerm(e.target.value)}
										onKeyPress={(e) => handleKeyPress(e)}
										data-bs-toggle="dropdown"
										aria-expanded="false"
									/>
									{searchTerm && (
										<button
											onClick={(e) => handleSearchTerm('')}
											style={{
												position: 'absolute',
												right: '5px',
												top: '57%',
												transform: 'translateY(-50%)',
												background: 'none',
												border: 'none',
												cursor: 'pointer',
												zIndex: 1,
											}}>
											<FontAwesomeIcon
												className="p-1"
												icon={faCircleXmark}
												style={{ color: 'gray' }}
											/>
										</button>
									)}
									<ul
										style={{ maxHeight: '600px' }}
										className={`dropdown-menu mt-1 ${showDropdown ? 'show w-100 z-3' : ''}`}>
										{searchTerm && loading ? (
											<li>
												<div className="search-result-message px-4 py-1 bw_med text-center">
													Searching Results
												</div>
											</li>
										) : searchTerm && updatedSuggestions.length > 0 ? (
											updatedSuggestions.map((item: any) => {
												// const highlightedTitle = item.title
												// 	.split(new RegExp(`(${searchTerm})`, 'gi'))
												// 	.map((part: string, index: number) =>
												// 		part.toLowerCase() === searchTerm.toLowerCase() ? (
												// 			<strong key={index}>{part}</strong>
												// 		) : (
												// 			part
												// 		),
												// 	);
												// 	const highlightedCategory = item.cms_category
												// 		.split(new RegExp(`(${searchTerm})`, 'gi'))
												// 		.map((part: any, index: number) =>
												// 			part.toLowerCase() === searchTerm.toLowerCase() ? (
												// 				<span key={index} className='highlightedCategoryColor'>{part}</span>
												// 			) : (
												// 				part
												// 			),
												// 		);
												const searchWords = searchTerm.toLowerCase().split(/\s+/).filter(Boolean); // Convert to lowercase

												const highlightedTitle = item.title
													.split(new RegExp(`(${searchWords.join('|')})`, 'gi'))
													.map((part: any, index: number) =>
														searchWords.includes(part.toLowerCase()) ? <strong key={index}>{part}</strong> : part
													);

												const highlightedCategory = item.cms_category
													.split(new RegExp(`(${searchWords.join('|')})`, 'gi'))
													.map((part: any, index: number) =>
														searchWords.includes(part.toLowerCase()) ? (
															<span key={index} className="highlightedCategoryColor">{part}</span>
														) : (
															part
														)
													);
												return (
													<li key={item.title} className="py-3 px-4 in_reg border-bottom">
														<div className="fs-12 mb-2 color34">{highlightedCategory}</div>
														<div>
															{reRouteButtonRenderer({
																url: `/category/${encodeURIComponent(
																	item?.parent_map[1],
																)}/${encodeURIComponent(item?.parent_map[0])}/${encodeURIComponent(
																	item.cms_category,
																)}/${encodeURIComponent(item.id)}`,
																classes: 'suggestion-item fs-6 p-0',
																nameText: highlightedTitle,
															})}
														</div>
													</li>
												);
											})
										) : (
											<li>
												<div
													className="no-results-message px-4 py-1 in_reg"
													style={{ borderRadius: '10px' }}>
													{searchTerm === '' ? 'Write anything to search' : 'No Result Found'}
												</div>
											</li>
										)}
									</ul>
								</div>
							</div>
							<div className="pt-4 text-decortion-none d-none">
								<ul className="d-flex justify-content-evenly list-unstyled">
									<li>
										<a href="" className="link-color1 text-decoration-underline">
											Bulletin
										</a>
										<sup className="px-1 text-decoration-none" style={{ color: '#2B948F' }}>
											New
										</sup>
									</li>
									<li>
										<a href="" className="link-color1 text-decoration-underline">
											Circulars
										</a>
									</li>
									<li>
										<a href="" className="link-color1 text-decoration-underline">
											Announcement
										</a>
									</li>
								</ul>
							</div>
							{/* <div className="fs-18 my-4 text-start ur_semi color28">Most Searched FAQs</div>
							<div className="row">
								<div className="col-12 col-md-6 col-lg-4 ">
									<ul>
										<li className="text-start">
											{reRouteButtonRenderer({
												url: `/category/1/1/Account/1`,
												classes: 'color37 text-decoration-none in_med lh-19',
												nameText:
													'How to activate and deactivate Securities Lending and Borrowing (SLB...',
											})}
											{reRouteButtonRenderer({
												url: `/category/`,
												classes: 'color38 fs-14 text-decoration-none in_reg',
												nameText: 'Addition & Withdrawal of Funds',
											})}
										</li>
									</ul>
								</div>
								<div className="col-12 col-md-6 col-lg-4 ">
									<ul>
										<li className="text-start">
											{reRouteButtonRenderer({
												url: `/category/1/1/Account/1`,
												classes: 'color37 text-decoration-none in_med lh-19',
												nameText:
													'How to activate and deactivate Securities Lending and Borrowing (SLB...',
											})}
											{reRouteButtonRenderer({
												url: `/category/`,
												classes: 'color38 fs-14 text-decoration-none in_reg',
												nameText: 'Addition & Withdrawal of Funds',
											})}
										</li>
									</ul>
								</div>
								<div className="col-12 col-md-6 col-lg-4 ">
									<ul>
										<li className="text-start">
											{reRouteButtonRenderer({
												url: `/category/1/1/Account/1`,
												classes: 'color37 text-decoration-none in_med lh-19',
												nameText:
													'How to activate and deactivate Securities Lending and Borrowing (SLB...',
											})}
											{reRouteButtonRenderer({
												url: `/category/`,
												classes: 'color38 fs-14 text-decoration-none in_reg',
												nameText: 'Addition & Withdrawal of Funds',
											})}
										</li>
									</ul>
								</div>
							</div> */}
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default HeroSection;
