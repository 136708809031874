import React, { useEffect, useState } from 'react';
import Navbar from '../common/Navbar/Navbar';
import Footer from '../footer/Footer';
import VideoSlider from '../common/VideoSlider/VideoSlider';
import { API_ENDPOINTS } from '../../assets/helpers/AssetHelpers';
import { CmsVideo } from '../../types/types';
import VideoHeader from './VideoHeader';

const VideoPage = () => {
	const [sliderVideoData, setSliderVideoData] = useState([]);
	const [allowedCategories, setAllowedCategories] = useState([]);
	const [expandedStates, setExpandedStates] = useState<boolean[]>([]);

	useEffect(() => {
		const fetchVideoData = async () => {
			try {
				const response = await fetch(API_ENDPOINTS.getVideo);
				if (!response.ok) {
					throw new Error(`Error: ${response.status}`);
				}
				const data = await response.json();

				const allCategories = data.data;

				const sortedCategories = allCategories
					.filter(
						(category: { parent_cms_category: string; rank: number }) =>
							category.parent_cms_category === 'Video' && category.rank !== 0,
					)
					.sort((a: { rank: number }, b: { rank: number }) => a.rank - b.rank);

				const dynamicCategories = sortedCategories.map(
					(category: { category_name: string }) => category.category_name,
				);

				setAllowedCategories(dynamicCategories);

				const filteredVideos = allCategories.reduce(
					(
						acc: any[],
						category: { category_name: string; cms_videos: any[]; video_category: string },
					) => {
						if (
							dynamicCategories.includes(category.category_name) &&
							category.cms_videos &&
							category.cms_videos.length > 0
						) {
							acc.push(
								...category.cms_videos.map((video: CmsVideo) => {
									const videoTags = video.element_note.split(' ');
									const srcTag =
										videoTags.find((tag: string | string[]) => tag.includes('src')) || '';
									const videoUrlTagsArray = srcTag.split('/');
									const videoId = videoUrlTagsArray[videoUrlTagsArray.length - 1].split('?')[0];

									return {
										id: videoId,
										element_note: video.element_note,
										parent: video.parent,
										title: video.video_title,
										category: video.video_category,
										video_parent_category: category.category_name, // Ensure this matches your API
									};
								}),
							);
						}
						return acc;
					},
					[],
				);

				setSliderVideoData(filteredVideos);
				setExpandedStates(new Array(dynamicCategories.length).fill(false));
			} catch (error) {
				console.error('Failed to fetch video data:', error);
			}
		};

		fetchVideoData();
	}, []);

	const toggleExpand = (index: number) => {
		setExpandedStates((prevStates) => prevStates.map((state, i) => (i === index ? !state : state)));
	};

	const getVideosByCategory = (category: string) => {
		return sliderVideoData.filter((video: any) => video.video_parent_category === category);
	};

	return (
		<>
			<Navbar />
			<section id="videoSection">
				<div className="container-fluid">
					<div className="row">
						<div className="col px-lg-5 px-3 py-4">
							<div className="row">
								<div className="col-lg-10 mx-auto px-md-4 px-lg-4">
									<VideoHeader />
									{allowedCategories.map((category, index) => {
										const videos = getVideosByCategory(category);

										if (videos.length > 0) {
											return (
												<div key={index} className="mt-4">
													<div className="ur_semi color1 fs-28">{category}</div>
													<div>
														<VideoSlider
															videoData={videos}
															buttonUrl="#"
															isExpanded={expandedStates[index]}
															onClick={() => toggleExpand(index)}
														/>
													</div>
												</div>
											);
										}
										return null;
									})}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
};

export default VideoPage;
