import React from "react";
import "./sidebar.css";
import "../../assets/sass/global.css";
import { Link } from "react-router-dom";

const Sidebar = ({
  category_name,
  category,
  index,
  url_category_name,
  url_child_category_name,
}: any) => {
  const isCategoryActive = category_name === url_category_name;
  return (
    <>
      {isCategoryActive && (
        <div
          className={`accordion-item sidebar-accordion-item bw_reg border-0 ${
            isCategoryActive ? "active" : ""
          }`}
        >
          <h2 className="accordion-header sidebar-accordion-header global-font">
            <button
              className={`accordion-button sidebar-accordion-button ${
                isCategoryActive ? "" : "collapsed"
              } px-0`}
              style={{
                backgroundColor: "white",
                border: "none",
                boxShadow: "none",
                color: isCategoryActive ? "" : "inherit",
              }}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#sideBarCollapse${index}`}
              aria-expanded={isCategoryActive ? "true" : "false"}
              aria-controls={`#sideBarCollapse${index}`}
            >
              {isCategoryActive ? category_name : ""}
            </button>
          </h2>
          {isCategoryActive && category && (
            <div
              id={`sideBarCollapse${index}`}
              className={`accordion-collapse sidebar-accordion-collapse ${
                isCategoryActive ? "show" : "collapse"
              }`}
              data-bs-parent="#sideBarAccordian"
            >
              <div className="sidebar-accordion-body">
                <ul className="list-unstyled">
                  {category?.subcategories &&
                    category?.subcategories?.map((subcategory: any) => {
                      const isThisSubcategoryActive =
                        subcategory?.category_name === url_child_category_name;
                      return (
                        <li
                          key={subcategory.id}
                          className={`py-2 global-font  ${
                            isThisSubcategoryActive ? "active" : ""
                          }`}
                        >
                          <a
                            href={`/category/${category_name}/${subcategory?.category_name}`}
                            className={`decoration fs-6 color1 bw_reg ${
                              isThisSubcategoryActive ? "color1" : "#9C9AA6"
                            }`}
                            style={{
                              backgroundColor: "white",
                              border: "none",
                              boxShadow: "none",
                              color: isThisSubcategoryActive
                                ? "color1"
                                : "#9C9AA6",
                            }}
                          >
                            {subcategory?.category_name}
                          </a>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Sidebar;
