export const toAbsoluteUrl = (pathname: string) => process.env.PUBLIC_URL + '/images/' + pathname;
export const SITE_URL = process.env.REACT_APP_SUPPORT_URL;
export const API_ENDPOINTS = {
	getCMSContent: 'https://erpcms.jainam.in/api/method/jainam_app.api.cms_page.get_cms_content',
	getCMSCategory:
		'https://erpcms.jainam.in/api/method/jainam_app.api.get_cms_category.get_cms_category',
	getCMSPage: 'https://erpcms.jainam.in/api/method/jainam_app.api.cms_page.get_content_by_faq',
	getSearch: 'https://erpcms.jainam.in/api/method/jainam_app.api.cms_page.get_cms_page_search',
	getVideo: 'https://erpcms.jainam.in/api/method/jainam_app.api.cms_page.get_cms_content_video',
	loginURL: 'https://jplus.jainam.in/?app=&returnUrl=',
};
