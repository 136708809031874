import React, { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../assets/helpers/AssetHelpers";
import images from "./logo.json";
import { useParams, useNavigate, Link } from "react-router-dom";
import useReRouteHook from "../../assets/hooks/useReRouteHook";
import { Categories } from "../../types/types";
import "./style.css"
interface Images {
  category_name: string;
  url: string;
  mobileUrl: string;
}
interface Card {
  id: string;
  category_name: string;
  is_group: number;
  parent_cms_category: string;
}

const MainSectionCard = ({ cards }: { cards: Omit<Categories, 'is_group' | 'level' | 'sorting_order'> }) => {
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const { reRouteButtonRenderer } = useReRouteHook();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const filterimage: Images[] = images?.filter(
    (item: any) => item.category_name === cards.category_name.toUpperCase()
  );

  return (
    <>
      {cards.parent_cms_category === null ||
        cards.parent_cms_category === "" ? (
        <div className="col-lg-4 col-md-6 col-12 px-4 px-lg-3">
          <div className="card p-0 border rounded-4 h-100">
            <div className="card-body pt-4 pb-3">
              <>
                <div className="d-flex align-items-center pb-2 border-bottom">
                  {filterimage.map((item: Images) => {
                    const imageUrl = isMobile ? item.mobileUrl : item.url;

                    return (
                      <div key={item.category_name}>
                        {reRouteButtonRenderer({
                          url: `/category/${cards.category_name}/`,
                          classes: `in_semi ms-2 color1 fs-20`,
                          nameText: cards?.category_name,
                          spanClasses: "color1",
                          startAdornment: <img
                            src={toAbsoluteUrl(`${imageUrl}`)}
                            alt={cards.category_name}
                            className="main-section-img"
                          />
                        })}
                      </div>

                    );
                  })}
                </div>
              </>
              <div className="col-lg-12 col-12">
                <ul className="list-unstyled cursor-pointer mb-0">
                  {cards.subcategories &&
                    cards.subcategories.map((subcategory: Categories) => {
                      const categorySlug = cards.category_name
                        .toLowerCase()
                        .replace(/[\s/]+/g, "-");
                      return (
                        <li
                          key={subcategory.id}
                          className="py-2 fs-16 color28 in_med"
                        >
                          {reRouteButtonRenderer({
                            url: `/category/${cards.category_name}/${subcategory?.category_name}`,
                            classes: "align-items-center color37 fs-14 d-flex justify-content-between w-100 link-hover",
                            nameText: subcategory.category_name,
                            endAdornment: <img
                              src={toAbsoluteUrl(
                                "mainsection-images/left-arrow.svg"
                              )}
                              alt={subcategory.id}
                            />
                          })}
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default MainSectionCard;
