import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useReRouteHook from '../../assets/hooks/useReRouteHook';
import './ChildHeader.css';
interface ChildHeaderProps {
	language: string;
	setLanguage: React.Dispatch<React.SetStateAction<string>>;
	hindiAvailable: boolean;
}

const ChildHeader: React.FC<ChildHeaderProps> = ({ language, setLanguage, hindiAvailable }) => {
	const { category_name, child_category_name, child } = useParams();
	const navigate = useNavigate();

	const { reRouteButtonRenderer } = useReRouteHook();
	if (!category_name || !child_category_name || !child) {
		navigate('/no-results-found');
		return null;
	}

	const handleLanguageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setLanguage(e.target.value);
	};

	return (
		<div className="d-flex pt-4 px-0">
			<div className="col-lg-12 col-12">
				<div className="align-items-center d-md-flex justify-content-between">
					<nav aria-label="breadcrumb" className="px-0 px-md-3 px-lg-0">
						<ol className="breadcrumb cursor-pointer in_reg fs-sm-14 m-0">
							<li className="breadcrumb-item active" aria-current="page">
								{reRouteButtonRenderer({
									url: '/',
									classes: 'ps-0 color1 in_reg',
									nameText: 'Home',
								})}
							</li>
							<li className="breadcrumb-item">
								{reRouteButtonRenderer({
									url: `/category/${category_name}/`,
									classes: 'color1 in_reg',
									nameText: category_name,
								})}
							</li>
							<li className="breadcrumb-item">
								{reRouteButtonRenderer({
									url: `/category/${category_name}/${child_category_name}`,
									classes: 'color1 in_reg',
									nameText: child_category_name,
								})}
							</li>
							<li className="breadcrumb-item">
								{reRouteButtonRenderer({
									url: `/category/${category_name}/${child_category_name}/${child}`,
									classes: 'color1 in_reg',
									nameText: child,
								})}
							</li>
						</ol>
					</nav>
					{hindiAvailable && (
						<div className="mt-3 mt-md-0">
							<div className="btn-group" role="group" aria-label="Language selection">
								<input
									type="radio"
									className="btn-check"
									name="language"
									id="english"
									value=""
									checked={language === ''}
									onChange={handleLanguageChange}
								/>
								<label
									className="btn btn-outline-primary lang-btn-check rounded-start-5 ps-3"
									htmlFor="english">
									English
								</label>

								<input
									type="radio"
									className="btn-check "
									name="language"
									id="hindi"
									value="hindi"
									checked={language === 'hindi'}
									onChange={handleLanguageChange}
								/>
								<label
									className="btn btn-outline-primary lang-btn-check rounded-end-5 pe-3"
									htmlFor="hindi">
									हिन्दी
								</label>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default ChildHeader;
