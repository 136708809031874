import React, { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../assets/helpers/AssetHelpers";
import "./subchildcontent.css";
import { Link, useParams } from "react-router-dom";
import useReRouteHook from "../../assets/hooks/useReRouteHook";
import { CmsContent } from "../../types/types";

const SubChildContent = ({
  filteredCategory,
  category_name,
  child_category_name,
  index,
}: any) => {
  const list = filteredCategory?.subcategories?.sort(
    (a: { sorting_order: number }, b: { sorting_order: number }) => {
      return a.sorting_order - b.sorting_order;
    }
  );
  const { reRouteButtonRenderer } = useReRouteHook();


  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDataLoaded(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <div
        className={`subContent-accordion_item accordion-item`}
        key={index}
        style={{ border: "none" }}
      >
        <div
          id={`subContentCollapse${index}`}
          className="subContent-accordion_collapse accordion-collapse collapse show"
          data-bs-parent="#subContent"
        >
          <div className="px-md-2 p-1 px-lg-2">
            {dataLoaded ? (
              <ul className="list-unstyled">
                {list && list.length > 0 ? (
                  list.map((subItem: CmsContent, subIndex: number) => (
          
                    <li
                      key={subIndex}
                      className="fs-6 "
                      style={{ padding: "12px 0" }}
                    >
                        {reRouteButtonRenderer({
                          url: `/category/${encodeURIComponent(category_name )}/${encodeURIComponent(child_category_name )}/${encodeURIComponent(filteredCategory?.category_name)}/${encodeURIComponent(subItem?.id)}`,
                          classes: "ps-0 color1 cursor-pointer in_med",
                          nameText: subItem?.title.trim(),
                          spanClasses:"subcategory-hover"
                          })}
                    </li>
                  ))
                ) : (
                  <div className="border-0 text-center">
                    <img
                      src={toAbsoluteUrl(
                        "about-section/no-faq.svg"
                      )}
                      alt="no_data_found"
                      style={{ width: "200px" }}
                    />
                    <p className="fs-6 py-2 bw_semi">There are no questions</p>
                  </div>
                )}
              </ul>
            ) : (
              <div className="loader3 py-4">
                <div className="bars bar1"></div>
                <div className="bars bar2"></div>
                <div className="bars bar3"></div>
                <div className="bars bar4"></div>
                <div className="bars bar5"></div>
                <div className="bars bar6"></div>
                <div className="bars bar7"></div>
                <div className="bars bar8"></div>
                <div className="bars bar9"></div>
                <div className="bars bar10"></div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SubChildContent;
